export default {
    "administration": "Administration",
    "admin_page": {
        "no_permission": "Sie haben keine Berechtigung für den Administrationsbereich.",
        "confirm_before_delete_msg": "Möchten Sie diesen Eintrag wirklich entfernen? (ID #{id})",
        "confirm_before_delete_from_db_msg": "Möchten Sie diesen Eintrag wirklich löschen? (ID #{id})",
        "confirm_before_delete_from_db_title": "Endgültig Löschen",
        "confirm_before_delete_from_db_btn": "Löschen",
        "block_user_msg": "Möchten Sie {name} wirklich sperren? Die Person kann sich dann nicht mehr anmelden.",
        "block_user_title": "Anmeldesperre",
        "block_user_btn": "Sperren",
        "unblock_user_msg": "Möchten Sie {name} wirklich entsperren? Die Person kann sich dann wieder anmelden.",
        "unblock_user_title": "Anmeldung entsperren",
        "unblock_user_btn": "Entsperren",
        "tour": {
            "menu_title": "Bereiche",
            "menu": "Hier sehen Sie die verschiedenen Bereiche der Administration, in denen Sie Stammdaten hinterlegen und bearbeiten sowie Konfigurationen vornehmen können.",
            "client_menu_title": "Unternehmen verwalten",
            "client_menu": "Hier können Sie Ihre Unternehmen hinzufügen, einsehen oder bearbeiten.",
            "client_list": "Hier sehen Sie die Liste aller Unternehmen.",
            "new_client": "Klicken Sie hier, um ein Unternehmen anzulegen.",
            "view_mode": "<p>Hier können Sie von der Listen- zur Tabellenansicht wechseln, um mehrere Stammdateneinträge auf einmal zu hinterlegen oder einen besseren Überblick zu haben.</p> Die Tabellenansicht ist z.B. auch für einen Datenex- oder -import nützlich. Vergessen Sie nicht, die Tabelle nach Bearbeitung abzuspeichern."
        }
    },
    "version": "Version",
    "yes": "Ja",
    "no": "Nein",
    "never": "Nie",
    "add": "Hinzufügen",
    "save": "Speichern",
    "cancel": "Abbrechen",
    "edit": "Bearbeiten",
    "delete": "Entfernen",
    "next": "Weiter",
    "back": "Zurück",
    "finish": "Beenden",
    "ok": "Ok",
    "open": "öffnen",
    "dashboard": "Dashboard",
    "overview": "Übersicht",
    "operator": "Operator",
    "profile": "Profil",
    "help": "Hilfe",
    "logout": "Logout",
    "list_view": "Listenansicht",
    "table_view": "Tabellenansicht",
    "archive": "Archiv",
    "active": "Aktiv",
    "email": "E-Mail",
    "name": "Name",
    "tax": "Steuer (%)",
    "value": "Betrag",
    "number": "Nummer",
    "currency": "Währung",
    "actions": "Aktionen",
    "system": "System",
    "from": "Von",
    "of": "Von",
    "until": "bis",
    "up_to": "bis",
    "cost_center_id": "Kostenstelle",
    "cost_centers": "Kostenstellen",
    "budget_id": "Budget",
    "budgets": "Budgets",
    "branch_id": "Niederlassung",
    "branches": "Niederlassungen",
    "employee_id": "Mitarbeitende",
    "employees": "Mitarbeitende",
    "d365_vehicle_id": "Kraftfahrzeug",
    "vehicles": "Kraftfahrzeuge",
    "financial_dimension_id": "Finanzdimension",
    "financial_dimensions": "Finanzdimensionen",
    "tax_group_id": "Steuergruppe",
    "tax_groups": "Steuergruppen",
    "tax_item_group_id": "Steuer-Artikelgruppe",
    "tax_item_groups": "Steuer-Artikelgruppen",
    "profit_center_id": "Profitcenter",
    "profit_centers": "Profitcenter",
    "client_id": "Unternehmen",
    "clients": "Unternehmen",
    "department_id": "Abteilung",
    "departments": "Abteilungen",
    "d365_department_id": "D365 Abteilung",
    "d365_departments": "D365 Abteilungen",
    "location_id": "Standort",
    "consignee_id": "Wareneingang",
    "address_id": "Lieferanschrift",
    "lb_user_id": "Person",
    "users": "Personen",
    "task_group_id": "Aufgabengruppe",
    "task_groups": "Aufgabengruppen",
    "user_task_group": "Person/Aufgabengruppe",
    "user_task_groups": "Personen/Aufgabengruppen",
    "products": "Produktkatalog",
    "product": "Produkt",
    "product_group_id": "Warengruppe",
    "product_groups": "Warengruppen",
    "supplier_id": "Lieferunternehmen",
    "suppliers": "Lieferunternehmen",
    "procurement_id": "Bestellanforderung",
    "procurements": "Bestellanforderungen",
    "user_select_product_id": "Person „Produktauswahl“",
    "user_send_order_id": "Person „Beauftragung“",
    "user_clearing_id": "Person „Klärung Einkauf“",
    "user_consignee_id": "Person „Wareneingang“",
    "user_invoice_manual_check_id": "Person „Manuelle Prüfung (Rechnung)“",
    "user_invoice_clearing_id": "Person „Klärung Buchhaltung“",
    "user_accounting_id": "Person „Buchhaltung“",
    "ledger_account_id": "Sachkonto",
    "ledger_accounts": "Sachkonten",
    "posting_key_id": "Steuerschlüssel",
    "posting_keys": "Steuerschlüssel",
    "permission_id": "Berechtigungsgruppe",
    "permissions": "Berechtigungsgruppen",
    "invoices": "Rechnungen",
    "invoice_id": "Rechnung",
    "configurations": "Konfigurationen",
    "notes": "Notiz",
    "search": "Suchen",
    "no_data": "Keine Einträge gefunden",
    "months": "Monate",
    "select_valid_option": "Wählen Sie einen gültigen Eintrag aus.",
    "procurement_line": {
        "description": "Produkt",
        "item_number": "Artikelnummer",
        "is_cost_center_fixed": "Kostenstelle für diesen Artikel sperren",
        "image_url": "Bild-URL",
        "webshop_url": "Shop-URL",
        "offer_number": "Angebotsnummer",
        "offer_date": "Angebotsdatum",
        "include_tax": "inkl. {tax} USt",
        "offer_msg": "Nach dem Angebot {offer_number}",
        "offer_date_msg": "vom {offer_date}",
        "total_price_brutto": "Gesamt-Brutto",
        "total_price_netto": "Gesamt-Netto",
    },
    "product_catalog": {
        "description": "Artikel",
        "add_product_btn": "Produkt hinzufügen",
        "item_number": "Artikelnummer",
        "is_cost_center_fixed": "Kostenstelle für diesen Artikel sperren",
        "image_url": "Bild-URL",
        "webshop_url": "Shop-URL",
        "offer_number": "Angebotsnummer",
        "offer_date": "Angebotsdatum",
        "type_price_netto": "float",
        "template_price_netto": "$1 €",
        "type_price_brutto": "float",
        "template_price_brutto": "$1 €",
        "form_sequence": "name,item_number,product_group_id,supplier_id,location_id,cost_center_id,is_cost_center_fixed,tax,price_netto,price_brutto,unit,image_url,webshop_url,offer_number,offer_date",
        "page": {
            "new": "Neues Produkt",
            "supplier_tooltip": "Wählen Sie ein Lieferunternehmen aus Ihren Stammdaten aus oder fügen Sie ein neues hinzu, indem Sie einen neuen Namen eintippen.",
            "supplier_new_tooltip": "Dieses Lieferunternehmen wird beim Hinzufügen des Produktes in Ihren Stammdaten gespeichert.",
            "should_save": "Im Produktkatalog speichern",
            "error_adding_product": "Beim Hinzufügen des Produktes ist ein Fehler aufgetreten.",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "supplier_required": "Bitte wählen Sie ein Lieferunternehmen aus oder geben Sie ein neues an.",
                "image_url_invalid": "Bitte geben Sie einen gültigen URL ein.",
                "webshop_url_invalid": "Bitte geben Sie einen gültigen URL ein.",
                "tax_invalid": "Bitte geben Sie eine gültige Steuer aus."
            },
            "tour": {
                "name": "Geben Sie einen aussagekräftigen Namen für das Produkt an.",
                "number": "Hier können Sie die Artikelnummer des Produkts erfassen.",
                "product_group": "Wählen Sie aus, zu welcher Warengruppe das Produkt gehört.",
                "supplier": "Wählen Sie aus, von welchem Lieferunternehmen das Produkt angeboten wird, bzw. bei welchem es bestellt werden soll.",
                "price": "Geben Sie einen Steuersatz sowie einen Netto- oder Brutto-Preis an. Der andere Preis wird dann mit dem Steuersatz berechnet.",
                "unit": "Geben Sie an, in welcher Einheit das Produkt bemessen wird, z.B. \"Stück\", \"kg\" oder \"Liter\".",
                "url": "Hier können Sie dem Produkt eine Bild-URL hinzufügen. Außerdem können Sie, falls vorhanden, einen Link zu der Artikelseite des Shops hinzufügen.",
                "offer": "Liegt zu dem Produkt ein Angebot vor, können hier Nummer und Datum des Angebots erfasst werden, damit diese auf der Bestell-PDF abgebildet werden.",
                "btn_save": "Speichern Sie das Produkt im Produktkatalog, wenn Sie alle Informationen hinzugefügt haben."
            }
        }
    },
    "product_group": {
        "form_sequence": "name,description,user_select_product_id",
        "page": {
            "new": "Neue Warengruppe",
            "no_product_group": "Ohne Warengruppe",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein."
            },
            "tour": {
                "name": "Geben Sie einen aussagekräftigen Namen für die Warengruppe an.",
                "btn_save": "Haben Sie alle Informationen angegeben, fügen Sie die Warengruppe hinzu."
            }
        }
    },
    "procurement": {
        "description": "Bestellanforderung",
        "order_description": "Bestellung",
        "name": "Bezeichnung",
        "number": "Bestellnummer",
        "order_number_formatted": "Bestellnummer",
        "creator_id": "Erstellt von",
        "created_at": "Erstelldatum",
        "delivery_date": "Lieferdatum",
        "delivery_address_id": "Lieferanschrift",
        "billing_address_text": "Rechnungsanschrift",
        "status": "Status",
        "total_brutto": "Gesamt-Brutto",
        "total_netto": "Gesamt-Netto",
        "total_netto_abbr": "-Netto",
        "payment_in_advance": "Vorkasse",
        "statuses": {
            "open": "Produktauswahl",
            "approved": "Beauftragung",
            "ordered": "Warten auf Lieferung",
            "declined": "Abgewiesen",
            "completed": "Abgeschlossen",
            "approval": "Freigabe",
            "clearing": "Klärung Einkauf",
            "draft": "Entwurf"
        },
        "page": {
            "no_delivery_address": "Keine Lieferanschrift",
            "no_budget": "Kein Budget",
            "new": "Neue Bestellanforderung",
            "create_btn": "Bestellanforderung starten",
            "copy_btn_tooltip": "Bestellanforderung kopieren",
            "delivery_date_tooltip": "Das Lieferdatum wird auf die Bestell-PDF gedruckt und kann dann an das Lieferunternehmen übermittelt werden.",
            "order_sender": "Beauftragung",
            "product_selector": "Produktauswahl",
            "product_search": "Produkte suchen",
            "before_leave_msg": "Ihre Bestellanforderung wurde nicht gespeichert. Sind Sie sicher, dass Sie die Seite verlassen wollen?",
            "confirm_without_approval_limit_msg": "Keine, der gewählten Personen besitzt die notwendige Freigabegrenze für den Wert dieser Bestellung. Entsprechende Freigaben können später noch hinzugefügt werden. Sind Sie sicher, dass Sie fortfahren wollen?",
            "confirm_without_approval_limit_title": "Freigabegrenze",
            "confirm_product_selection_without_product_msg": "Sie haben kein Produkt ausgewählt. Sind Sie sicher, dass Sie fortfahren wollen?",
            "confirm_product_selection_without_product_title": "Kein Produkt ausgewählt",
            "no_approval_limit_msg": "Ihre Freigabegrenze reicht für diese Bestellung nicht aus. Bitte fügen Sie eine weitere Person zur Freigabe hinzu, welche eine ausreichende Freigabegrenze besitzt.",
            "no_approval_limit_title": "Freigabegrenze",
            "product_group_missing_msg": "Alle Produkte müssen einer Warengruppe zugeordnet sein. Gehen Sie oberhalb der Produktliste auf den Reiter \"Warengruppen\" und ordnen Sie jedem Produkt eine Warengruppe zu.",
            "product_group_missing_title": "Warengruppe zuordnen",
            "product_group_missing_btn": "Verstanden",
            "min_approval_one_person": "1 Person",
            "min_approval_qty": "{qty} verschiedenen Personen",
            "min_approval_error_msg": "Die Freigabe reicht für diese Bestellanforderung nicht aus. Bitte fügen Sie eine weitere Person zur Freigabe hinzu. Diese Bestellanforderung muss von <b>mindestens {min_approval_msg}</b> freigegeben werden.",
            "min_approval_error_title": "Freigabe nicht ausreichend",
            "order_not_completed_msg": "Sie haben nicht alle Bestellungen beauftragt oder bestätigt. Vergewissern Sie sich in der Produktliste, dass Sie alle Produkte bestellt haben und bestätigen Sie diese ggf. um fortzufahren.",
            "order_not_completed_title": "Bestellungen erst beauftragen",
            "order_not_completed_btn": "Verstanden",
            "confirm_delivery_not_completed_msg": "Sie haben nicht alle Wareneingänge bestätigt. Sind Sie sicher, dass Sie die Bestellanforderung abschließen wollen? <br> <i>Diese Aktion kann nicht rückgängig gemacht werden</i>.",
            "confirm_delivery_not_completed_title": "Ausstehende Wareneingänge",
            "confirm_delivery_not_completed_btn": "Abschließen",
            "default_confirm_btn": "Fortfahren",
            "budget_exceeded_msg": "Budget überschritten",
            "approval_limit_exceeded_msg": "Freigabegrenze überschritten",
            "external_order_number_msg": "Geben Sie die externe Bestellnummer ein.",
            "external_order_number_title": "Externe Bestellnummer erfassen",
            "no_edit_permission": "Ihnen fehlt die Berechtigung zu Bearbeitung der Daten. Bitte senden Sie die Bestellanforderung über die Aktionen zur Klärung, um Änderungen an den Daten vorzunehmen.",
            "delivery_dialog": {
                "title": "Wareneingang bestätigen",
                "confirm_btn": "Bestätigen",
                "message": "Geben Sie die zuletzt eingegangene Menge an.<br> Es fehlen <b>{missing_qty} von {quantity}</b> {unit}.",
                "qty_exceeded_msg": "Die fehlende Warenmenge darf nicht überschritten werden.",
                "qty_must_be_greater_msg": "Die Warenmenge muss größer als 0 sein.",
                "invalid_format": "Verwenden Sie ein gültiges Zahlenformat."
            },
            "order_sender_dialog": {
                "title": "Vorbelegung von Beauftragung",
                "info": "Die Person im Schritt „Beauftragung“ ergibt sich aus verschiedenen Vorbelegungen von zuständigen Personen je Abteilung, Unternehmen, einer globalen Rollenvorbelegung und der Person, welche die Bestellung erstellt hat. Die Person, die in der Liste zuoberst steht, wird für die Aufgabe vorbelegt."
            },
            "consignee_dialog": {
                "title": "Vorbelegung vom Wareneingang",
                "info": "Die Person im Schritt „Wareneingang“ ergibt sich aus verschiedenen Vorbelegungen von zuständigen Personen je Abteilung, Unternehmen, einer globalen Rollenvorbelegung und der Person, welche die Bestellung erstellt hat. Die Person, die in der Liste zuoberst steht, wird für die Aufgabe vorbelegt."
            },
            "select_product_dialog": {
                "title": "Vorbelegung von Produktauswahl",
                "info": "Die Person im Schritt „Produktauswahl“ ergibt sich aus verschiedenen Vorbelegungen von zuständigen Personen je Abteilung, Unternehmen, einer globalen Rollenvorbelegung und der Person, welche die Bestellung erstellt hat. Die Person, die in der Liste zuoberst steht, wird für die Aufgabe vorbelegt."
            },
            "dialog_assignment": {
                "department_form": "Abteilung im Formular",
                "department_creator": "Abteilung der bestellenden Person",
                "client_form": "Unternehmen im Formular",
                "client_creator": "Unternehmen der bestellenden Person",
                "no_selection": "noch nicht ausgewählt",
                "no_action": "nicht anwendbar",
                "configuration": "Globale Vorbelegung"
            },
            "draft": {
                "title": "Entwurf",
                "save": "Entwurf speichern",
                "delete": "Entwurf löschen",
                "confirm_before_delete_msg": "Sind Sie sicher, dass Sie diesen Entwurf endgültig löschen wollen?",
                "confirm_before_delete_title": "Entwurf löschen",
                "confirm_before_delete_btn": "Löschen"
            },
            "punchout": {
                "pending_msg": "Ausstehende Punch-out-Produkte ansehen.",
                "error_title": "Fehler beim Punch-out",
                "error_msg": "Es ist ein Fehler aufgetreten. Bitte prüfen Sie die Punch-out-Konfigurationen oder wenden sich an eine Person mit Administratorenrechten.",
                "error_start_url_msg": "Es wurde keine Start-URL bereitgestellt, daher kann Punch-out nicht gestartet werden.",
                "no_config": "Hinterlegen Sie zunächst die Konfiguration, um Punch-out nutzen zu können.",
                "pending_dialog": {
                    "title": "Ausstehende Punch-out-Produkte",
                    "add_products": "Zur Bestellung hinzufügen",
                    "add_all_products": "Alles zur Bestellung hinzufügen",
                    "delete_products": "Produkte entfernen"
                },
                "delete_dialog": {
                    "title": "Produkte von {supplier} #{index} entfernen",
                    "info1": "Möchten sie wirklich alle folgenden Produkte von {supplier} #{index} entfernen?",
                    "info2": "Diese Aktion kann nicht rückgängig gemacht werden.",
                    "delete_btn": "Entfernen"
                },
                "log_dialog": {
                    "title": "Punch-out Anfrage/Antwort",
                    "request": "Anfrage",
                    "response": "Antwort",
                    "no_log": "Kein Log",
                },
                "loading": "Bitte schließen Sie Ihre Produktauswahl in dem geöffneten Pop-up ab. Sollte sich kein Pop-up öffnen, müssen Sie dieses zunächst erlauben.",
                "can_not_edit": "Punch-out Produkte können nicht geändert werden. Bitte führen Sie Punch-out erneut durch. oder löschen Sie den kompletten Warenkorb.",
                "no_provider_found": "Der gewählte Punch-out Anbieter wird derzeit leider noch nicht unterstützt."
            },
            "download_pdf": {
                "title": "Download PDF",
                "confirm_btn": "Bestätigen",
                "title_confirm_save_changes": "Ungesicherte Daten speichern",
                "text_confirm_save_changes": "Wollen Sie die ungesicherten Daten speichern und für das Bestelldokument übernehmen?",
                "invalid_form": "Bitte füllen Sie alle Pflichtfelder im Formular aus, bevor Sie mit der Aktion fortfahren."
            },
            "lines": {
                "no_line": "Keine Produkte ausgewählt, bitte fügen Sie Produkte hinzu.",
                "show_all_info": "Erweiterte Ansicht",
                "invoice": {
                    "title": "Rechnung",
                    "created_at": "Erfasst",
                    "date": "Rechnungsdatum",
                    "created_by": "Erstellt von"
                },
                "automatic_order": "Elektronisch absenden",
                "automatic_ordered": "Elektronisch abgesendet",
                "confirm_order": "Beauftragung bestätigen",
                "finish_order": "Beauftragung abschließen",
                "order_finished": "Beauftragung abgeschlossen",
                "confirm_order_tooltip": "Bitte beachten Sie, dass Sie die Bestellung bei dem Lieferunternehmen <b>per E-Mail beauftragen</b> müssen,<br> die Bestellung wird <b>nicht elektronisch übermittelt.</b>",
                "ordered_at": "Übermittelt",
                "ordered_by": "Von",
                "confirm_receipt": "Wareneingang bestätigen",
                "no_cost_center": "Keine Kostenstelle",
                "include_taxes": "inkl. USt",
                "budget": "Budget",
                "no_budget": "Kein Budget",
                "no_product_group": "Keine Warengruppe",
                "no_ledger_account": "Kein Sachkonto",
                "select_product": "Produktauswahl",
                "quantity_to_show": "eingegangen: <b>{quantity_received} von {quantity}</b>",
                "from": "von",
                "item_number": "Art-Nr. #",
                "delete_msg": "Möchten Sie dieses Produkt wirklich entfernen?",
                "delete_title": "Produkt entfernen",
                "delete_btn": "Entfernen",
                "automatic_order_dialog": {
                    "confirm_order": "Bestellung absenden",
                    "confirm_order_btn": "Absenden",
                    "finish_order": "Bestellung abschließen",
                    "finish_order_btn": "Abschließen",
                    "info1": "Möchten Sie diese Bestellung <span style=\"font-weight: bold\">jetzt verbindlich elektronisch </span>an das Lieferunternehmen <span style=\"font-weight: bold\">senden?</span>",
                    "info2": "Diese Aktion kann nicht rückgängig gemacht werden."
                },
                "order_dialog": {
                    "confirm_order": "Beauftragung bestätigen",
                    "confirm_order_btn": "Bestätigen",
                    "finish_order": "Beauftragung abschließen",
                    "finish_order_btn": "Abschließen",
                    "info1": "Haben Sie den Auftrag an das Lieferunternehmen übermittelt? (z. B. per E-Mail oder Fax)",
                    "info2": "Diese Aktion kann nicht rückgängig gemacht werden."
                },
                "more_actions": {
                    "delete_products": "Produkte entfernen",
                    "add_external_id": "Externe Bestellnummer erfassen",
                    "download_pdf": "Bestellung (PDF)",
                    "download_pdf_with_attachments": "Bestellung inkl. Anhänge",
                    "write_email": "Anschreiben",
                    "outlook_with_pdf": "Outlook inkl. PDF",
                    "email_with_pdf": "E-Mail inkl. PDF"
                },
                "file": {
                    "open_btn": "PDF öffnen",
                    "add_btn": "PDF hinzufügen",
                    "upload_info1": "Per Drag-and-drop ablegen <br>oder <br> <em>Datei auswählen</em>.",
                    "upload_info2": "PDF-Dateien bis zu 10MB.",
                    "delete_msg": "Möchten Sie dieses Dokument wirklich entfernen?",
                    "delete_title": "Dokument entfernen",
                    "delete_btn": "Entfernen",
                    "delete_error_msg": "Das Dokument konnte nicht entfernt werden."
                }
            },
            "validation": {
                "delivery_date_format": "Bitte geben Sie ein Lieferdatum ein.",
                "client_id_required": "Bitte wählen Sie ein Unternehmen aus.",
                "department_id_required": "Bitte wählen Sie eine Abteilung aus.",
                "department_id_required_client_missing": "Erst Unternehmen auswählen.",
                "cost_center_id_required": "Bitte wählen Sie eine Kostenstelle aus.",
                "cost_center_id_required_filters_missing": "Erst Unternehmen und Abteilung auswählen.",
                "cost_center_id_required_department_missing": "Erst Abteilung auswählen.",
                "cost_center_id_required_client_missing": "Erst Unternehmen auswählen.",
                "consignee_id_required": "Bitte wählen Sie eine zuständige Person aus.",
                "delivery_address_id_required": "Bitte wählen Sie eine Lieferanschrift aus.",
                "billing_address_text_required": "Die Rechnungsanschrift fehlt in der Konfiguration des Unternehmens."
            },
            "tour": {
                "main": {
                    "description": "In der ersten Zeile geben Sie eine Bezeichnung der Bestellanforderung an. Außerdem sehen Sie hier die Person, welche die Bestellung angelegt hat, sowie das Erstelldatum.",
                    "organization": "Hier geben Sie das Unternehmen, die Abteilung und die Kostenstelle an, für welche die Bestellanforderung aufgegeben werden soll. Die Abteilung wird nach dem Unternehmen und die Kostenstelle nach der Abteilung gefiltert.",
                    "address": "Die Lieferanschrift, welche dem Lieferunternehmen mitgeteilt werden soll. Sie können aus den Anschriften des Unternehmens, der Person im Wareneingang und Ihren Anschriften wählen.",
                    "total": "Hier sehen Sie den Gesamtwert Ihrer Bestellanforderung, welcher sich aus der Summe der ausgewählten Produkte ergibt.",
                    "delivery_date": "Das Lieferdatum kann nach dem Wareneingang zur Dokumentation erfasst werden. Es wird nicht an das Lieferunternehmen gesendet.",
                    "product_selector": "Hier sehen Sie, welche Person im nächsten Schritt für die Produktauswahl zuständig ist. Sie hängt von verschiedenen Vorbelegungen ab, welche Sie detaillierter sehen, wenn Sie auf das Info-Icon klicken.",
                    "order_sender": "Hier sehen Sie, welche Person im vorletzten Schritt für die Beauftragung der Bestellung zuständig ist. Sie hängt von verschiedenen Vorbelegungen ab, welche Sie detaillierter sehen, wenn Sie auf das Info-Icon klicken.",
                    "consignee": "Wählen Sie hier die Person aus, welche die Ware in Empfang nehmen soll. Die gewählte Person bestätigt dann im letzten Schritt der Bestellanforderung den Eingang der bestellten Ware und schließt die Bestellanforderung ab.",
                    "approvers": "Hier sehen Sie, ob für die gewählte Kostenstelle eine Freigabe konfiguriert wurde. Außerdem können Sie weitere Personen zur Freigabe hinzufügen.",
                    "product_search": "Hier können Sie ein Produkt aus dem internen Produktkatalog suchen und der Bestellanforderung hinzufügen.",
                    "punchout": "Hier können Sie Produkte bei integrierten Marktplätzen wie Amazon oder Mercateo auswählen. Die so getätigte Produktauswahl kann nach der Freigabe der Bestellanforderung einfach elektronisch an den Marktplatz übermittelt und somit bestellt werden.",
                    "new_product": "Ist Ihr gewünschtes Produkt noch nicht im Produktkatalog, können Sie es hier der Bestellanforderung hinzufügen.",
                    "save_btn": "Wenn Sie alle Informationen erfasst haben, können Sie die Bestellanforderung starten und zum nächsten Schritt weiterleiten."
                },
                "product": {
                    "pdf": "Wenn Sie hier klicken, können Sie dem Produkt Dokumente hinzufügen.",
                    "remove": "Sie können Produkte auch wieder aus der Auswahl entfernen, indem Sie hier klicken.",
                    "more_actions": "Sie können hier nach Erhalt die externe Bestellnummer erfassen.",
                    "tabs": "Hier können Sie die Ansicht Ihrer Produkte nach Lieferunternehmen, Kostenstellen oder Warengruppen gruppieren.",
                    "expand_view": "Hier können Sie erweiterte Einstellungen zu den Produkten ein- bzw. ausblenden, um diesen eine Kostenstelle, Lieferanschrift oder Warengruppe zuzuordnen."
                },
                "new_product": {
                    "name": "Geben Sie einen aussagekräftigen Namen für das Produkt an.",
                    "number": "Sie können außerdem noch eine Artikelnummer für das Produkt erfassen.",
                    "supplier": "Wählen Sie aus, von welchem Lieferunternehmen das Produkt angeboten wird, bzw. bei welchem es bestellt werden soll.",
                    "price": "Geben Sie einen Steuersatz sowie einen Netto- oder Brutto-Preis an. Der andere Preis wird dann mit dem Steuersatz berechnet.",
                    "unit": "Geben Sie an, in welcher Einheit das Produkt bemessen wird, z. B. \"Stück\", \"kg\" oder \"Liter\".",
                    "url": "Hier können Sie dem Produkt eine Bild-URL hinzufügen. Außerdem können Sie, falls vorhanden, einen Link zu der Artikelseite des Shops hinzufügen.",
                    "offer": "Liegt zu dem Produkt ein Angebot vor, können hier Nummer und Datum des Angebots erfasst werden, damit diese auf der Bestell-PDF abgebildet werden.",
                    "should_save": "Hier können Sie wählen, ob das neue Produkt im Produktkatalog gespeichert werden soll. Dadurch kann das spezifische Produkt auch zu weiteren Bestellungen hinzugefügt werden.",
                    "save_btn": "Fügen Sie das Produkt der Bestellanforderung hinzu, wenn Sie alle Informationen erfasst haben."
                },
                "cost_center_view": "Kostenstellen können auch einzelnen Produkten einer Bestellanforderung zugewiesen werden. Die in der Kostenstelle hinterlegten Lieferadressen können dann für das Produkt ausgewählt werden.",
                "product_group_view": "Jedem Produkt kann eine Warengruppe zugewiesen werden. Die für die Warengruppe zuständige Person erhält in der Timeline dann eine Aufgabe, um die Produktauswahl zu bearbeiten oder zu bestätigen.",
                "send_order": {
                    "send_btn": "Im Schritt der Beauftragung können Sie hier die Bestellung elektronisch absenden bzw. die bei dem Lieferunternehmen per Mail getätigte Bestellung bestätigen.",
                    "more_actions": "In den Optionen zu den ausgewählten Lieferunternehmen können Sie eine Bestell-PDF oder eine E-Mail mit oder ohne Bestell-PDF an das Lieferunternehmen generieren. Auf diese Weise können Sie die Bestellung bei dem Unternehmen beauftragen oder z. B. eine Rückfrage per Mail klären."
                },
                "delivery_btn": "Wenn Sie eine Teillieferung oder die gesamte Ware erhalten haben können Sie dieses hier vermerken. Der bestätige Wareneingang wird dann auch in der Timeline vermerkt."
            }
        }
    },
    "quantity": "Menge",
    "price_netto": "Netto-Preis",
    "price_brutto": "Brutto-Preis",
    "unit": "Einheit",
    "default_unit": "Stück",
    "tenant": {
        "tab_general": "Allgemein",
        "description": "Tenant",
        "language": "Hauptsprache",
        "monthly_procurement_limit": "BANF pro Monat im Grundpreis",
        "monthly_invoice_limit": "Rechnung pro Monat im Grundpreis",
        "base_price_procurement": "BANF Grundpreis",
        "base_price_invoice": "Rechnung Grundpreis",
        "price_extra_procurement": "Preis jeder weiteren Bestellung",
        "price_extra_invoice": "Preis jeder weiteren Rechnung",
        "easybill_id": "Easybill ID",
        "bank_account": "Kontonummer",
        "bank_account_owner": "Kontoinhabende Person",
        "bank_bic": "BIC",
        "bank_code": "Bankleitzahl",
        "bank_iban": "IBAN",
        "bank_name": "Name der Bank",
        "address": "Rechnungsanschrift",
        "sepa_agreement": "SEPA Lastschriftverfahren",
        "sepa_agreement_date": "SEPA - Eingangsdatum des Mandates",
        "sepa_mandate_reference": "Mandatsreferenz",
        "automatic_billing": "Abrechnung automatisch erstellen",
        "automatic_billing_mail": "Abrechnung automatisch per E-Mail senden",
        "bank": "Bankverbindung",
        "datev": "DATEV",
        "billing_address": {
            "name": "Firma",
            "consignee_name": "Name empfangender Person",
            "street": "Straße und Nr.",
            "zipcode": "PLZ",
            "city": "Stadt",
            "state": "Bundesland",
            "country": "Land",
            "phone": "Telefonnummer"
        },
        "contact_email": "Kontakt-E-Mail",
        "billing_email": "Rechnungs-E-Mail",
        "contact": "Kontaktdaten",
        "sepa": "SEPA Lastschrift",
        "add_sepa": "SEPA Lastschrift hinterlegen",
        "remove_sepa": "SEPA Lastschrift widerrufen",
        "import_invoice_email": "Automatische Rechnungserstellung per E-Mail",
        "tab_mailbox": "Postfächer"
    },
    "client": {
        "description": "Unternehmen",
        "min_approval": "Mindestanzahl Freigebende",
        "branch_required": "Die Niederlassung ist für das Rechnungsformular erforderlich.",
        "department_required": "Die Abteilung ist für das Rechnungsformular erforderlich.",
        "cost_center_required": "Die Kostenstelle ist für das Bestellformular pflicht.",
        "client_number": "Nummer",
        "vat_number": "USt-IdNr.",
        "address_name": "Name",
        "consignee": "Name empfangender Person - Rechnungsanschrift",
        "dropoff_location": "Name des Abgabeorts - Rechnungsanschrift",
        "address": "Straße und Nr. - Rechnungsanschrift",
        "address_complement": "Straße 2 - Rechnungsanschrift",
        "country": "Land - Rechnungsanschrift",
        "zipcode": "PLZ - Rechnungsanschrift",
        "city": "Stadt - Rechnungsanschrift",
        "phone_country_code": "Telefonland - Rechnungsanschrift",
        "phone_number": "Telefonnummer - Rechnungsanschrift",
        "can_delete_document_directly": "Dokumente können ohne Klärung löscht werden.",
        "form_sequence": "name,email,client_number,currency,min_approval,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id,user_invoice_manual_check_id,user_invoice_clearing_id",
        "page": {
            "tab_general": "Allgemein",
            "tab_billing_address": "Rechnungsanschrift",
            "tab_shipping_address": "Lieferanschriften",
            "tab_default_roles": "Allgemeine Rollenvorbelegungen",
            "tab_permissions": "Berechtigungsgruppen",
            "tab_departments": "Abteilungen",
            "tab_cost_centers": "Kostenstellen",
            "tab_ledger_accounts": "Sachkonten",
            "new": "Neues Unternehmen",
            "select_logo": "Logo auswählen",
            "select_logo_tooltip_content": "Fügen Sie eine Logo hinzu, welche auf das Beauftragungsdokument Ihrer freigegebenen Bestellungen gedruckt wird. <br/> Nehmen Sie am besten eine Bilddatei mit Hintergrund-Transparenz.",
            "billing_address": "Rechnungsanschrift",
            "roles": "Allgemeine Rollenvorbelegungen",
            "user_clearing_id_tooltip_content": "Für die Klärung in Bestellabläufen.",
            "user_invoice_clearing_id_tooltip_content": "Für die Klärung während der Rechnungsablage.",
            "no_selection": "Keinem bestimmten Unternehmen zugeordnet, daher zu allen Unternehmen zugehörig.",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "name_max_length": "Darf höchstens 50 Zeichen enthalten.",
                "email_invalid": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
                "client_number_invalid": "Bitte geben Sie eine gültige Nummer an.",
                "min_approval_invalid": "Bitte geben Sie eine gültige Nummer an.",
                "address_incomplete": "Bitte vervollständigen Sie die Pflichtdaten  der Anschrift.",
                "delivery_address_missing": "Fügen Sie zumindest eine Lieferanschrift hinzu."
            },
            "tour": {
                "name": "Geben Sie zunächst den Namen Ihres Unternehmens an.",
                "email": "Geben Sie die E-Mail Ihres Unternehmens an, an die z.B. die Rechnungszustellung gesendet werden soll.",
                "timeline_assignment": "Sie können für das Unternehmen optional zuständige Personen für die verschiedenen Schritte der Bestellanforderungen vorbelegen. Zum Beispiel, wenn es immer eine Person gibt, welche die Bestellung beauftragen soll.",
                "billing_address": "Die Rechnungsanschrift wird für die Bestellung benötigt und ggf. mit der Bestellung an das Lieferunternehmen gesendet.",
                "shipping_address": "Hier können Sie eine oder mehrere Lieferanschriften erfassen, aus denen in einer Bestellanforderung gewählt werden kann.",
                "btn_save": "Nach dem Sie alle benötigten Informationen erfasst haben, fügen Sie das Unternehmen hinzu."
            }
        }
    },
    "department": {
        "description": "Abteilung",
        "code": "Nummer",
        "form_sequence": "name,code,client_id,user_select_product_id,user_send_order_id,user_clearing_id,user_consignee_id",
        "page": {
            "new": "Neue Abteilung",
            "tab_general": "Allgemein",
            "tab_clients": "Unternehmen",
            "tab_cost_centers": "Kostenstellen",
            "tab_approvers": "Freigabe",
            "tab_permissions": "Berechtigungsgruppen",
            "tab_employees": "Personen",
            "tab_assignment": "Rollenvorbelegungen",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "email_required": "Bitte geben Sie eine gültige E-Mail-Adresse ein."
            },
            "tour": {
                "name": "Geben Sie zunächst den Namen der Abteilung an.",
                "client": "Wenn Sie die Abteilung einem Unternehmen zuordnen, kann die Abteilung beim Anlegen einer Bestellanforderung nur in diesem Unternehmen ausgewählt werden.",
                "timeline_assignment": "Sie können in der Abteilung für die verschiedenen Schritte der Bestellanforderungen zuständige Personen bestimmen. Zum Beispiel, wenn es immer eine Person je Abteilung im Wareneingang gibt.",
                "cost_centers": "Hier können Sie die bereits angelegten Kostenstellen der Abteilung zuordnen. Wenn Sie keine Kostenstellen zuordnen, können bei Bestellanforderungen der Abteilung nur Kostenstellen gewählt werden, die gar keiner Abteilung zugeordnet sind.",
                "employees": "Hier können Sie verschiedene Personen hinzufügen, die dieser Abteilung zuzuordnen sind. Legen die Personen dieser Abteilung eine Bestellanforderungen an, wird die Abteilung vorausgefüllt.",
                "btn_save": "Haben Sie alle Informationen angegeben, fügen Sie die Abteilung hinzu."
            }
        }
    },
    "permission": {
        "description": "Berechtigungsgruppe",
        "name": "Bezeichnung",
        "page": {
            "new": "Neue Berechtigungsgruppe",
            "validation": {
                "name_required": "Bitte geben Sie eine Bezeichnung ein."
            },
            "tour": {
                "name": "Hier können Sie eine Berechtigungsgruppe anlegen. Diese Gruppe können Sie dann verschiedenen Entitäten (Abteilungen, Produkten aus dem Warenkatalog, Warengruppen etc.) zuordnen. Den Personen können Sie ebenfalls eine oder mehrere Berechtigungsgruppen zuweisen. Soll eine Entität keine Berechtigungsbeschränkungen haben, ordnen Sie keine Gruppe zu."
            },
            "no_selection": "Keine Berechtigungsgruppenbeschränkung, daher für alle sichtbar."
        }
    },
    "task_group": {
        "description": "Aufgabengruppe",
        "name": "Bezeichnung",
        "page": {
            "new": "Neue Aufgabengruppe",
            "validation": {
                "name_required": "Bitte geben Sie eine Bezeichnung ein."
            },
            "tour": {
                "name": "Hier können Sie eine Aufgabengruppe erstellen. Sie können dann Aufgaben dieser Gruppe zuweisen."
            },
            "no_selection": "Keine Aufgabengruppe"
        }
    },
    "budget": {
        "name": "Name",
        "description": "Budgets",
        "label_log": "value€ (start - end)",
        "label_log_fields": "value,start,end",
        "value": "Betrag",
        "template_value": "$1 €",
        "type_value": "float",
        "start": "Gültig ab",
        "end": "Gültig bis",
        "add_foreach_ledger_account": "Alle Sachkonten einfügen",
        "component": {
            "validation": {
                "end_date_before_start_date": "Das Enddatum darf nicht vor dem Startdatum liegen.",
                "end_date_required": "Das Enddatum darf hier nicht leer sein.",
                "time_overlap": "Die Budgets dürfen sich zeitlich nicht überschneiden.",
                "field_missing": "Bitte geben Sie alle Informationen ein."
            }
        }
    },
    "address": {
        "description": "Lieferanschrift",
        "description_plural": "Lieferanschriften",
        "address_name": "Name",
        "consignee": "Name empfangender Person",
        "dropoff_location": "Name des Abgabeorts",
        "address": "Straße und Nr.",
        "address_complement": "Straße 2",
        "complement": "Straße 2",
        "country": "Land",
        "zipcode": "PLZ",
        "city": "Stadt",
        "phone_country_code": "Telefonland",
        "phone_number": "Telefonnummer",
        "component": {
            "new": "Neue Lieferanschrift",
            "copy_btn": "Rechnungsanschrift kopieren",
            "required_field": "Bitte vervollständigen Sie die Pflichtdaten der Anschrift.",
            "phone_required": "Bitte geben Sie beide Informationen ein.",
            "no_address": "Keine Lieferanschrift"
        }
    },
    "cost_center": {
        "name": "Bezeichnung",
        "number": "Nummer",
        "free_budget": "Frei",
        "description": "Kostenstelle",
        "label_log": "cost_center_name",
        "budgets": "Budgets",
        "form_sequence": "name,number",
        "page": {
            "new": "Neue Kostenstelle",
            "department_tooltip_content": "Welche Abteilungen dürfen diese Kostenstelle verwenden.",
            "tab_general": "Allgemein",
            "tab_budgets": "Budgets",
            "tab_shipping_address": "Lieferanschriften",
            "tab_approvers": "Freigabe",
            "tab_permissions": "Berechtigungsgruppen",
            "tab_departments": "Abteilungen",
            "tab_clients": "Unternehmen",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "number_required": "Bitte geben Sie eine Nummer ein.",
                "number_length": "Darf höchstens 36 Zahlen und Buchstaben enthalten und Leerzeichen dürfen nur in der Mitte vorkommen.",
                "number_exists": "Die Nummer existiert bereits."
            },
            "tour": {
                "name_number": "Geben Sie einen Namen und eine eindeutige Nummer für die Kostenstelle an.",
                "budget": "Sie können zu Ihrer Kostenstelle verschiedene Budgets angeben und die Budgets jeweils auch einem Sachkonto zuordnen.",
                "approver": "Definieren Sie hier optional, welche Personen Bestellanforderungen mit dieser Kostenstelle freigeben sollen. Weitere Freigaben können beim Anlegen von Bestellanforderungen hinzugefügt werden.",
                "department": "Hier können Sie definieren, in welchen Abteilungen die Kostenstelle verwendet werden kann. Wenn Sie hier keine Abteilungen hinzufügen, kann die Kostenstelle in allen Abteilungen ausgewählt werden.",
                "btn_save": "Nachdem Sie alle nötigen Informationen angegeben haben, fügen Sie die Kostenstelle der Datenbank hinzu."
            }
        }
    },
    "supplier": {
        "address": "Anschrift",
        "external_id": "DATEV ID",
        "supplier_number": "Nummer",
        "delivery_in_days": "Lieferzeit in Werktagen",
        "delivery_terms": "Lieferbedingungen",
        "payment_terms": "Zahlungsbedingungen",
        "vat_number": "USt-IdNr.",
        "city": "Stadt",
        "iban": "IBAN",
        "bic": "BIC",
        "form_sequence": "name,email,city,address,external_id,delivery_terms,payment_terms,iban,bic,vat_number",
        "page": {
            "new": "Neues Lieferunternehmen",
            "department_tooltip_content": "Welche Abteilungen dürfen dieses Lieferunternehmen verwenden.",
            "delivery_in_days_tooltip_content": "Die Lieferzeit wird für die Aufgaben-E-Mail des Wareneingangs verwendet.",
            "external_id_tooltip_content": "Die ID des Lieferunternehmens aus einem von Ihnen angebundenen Drittsystem.",
            "delivery_terms_tooltip_content": "Die mit dem Lieferunternehmen vereinbarten Zahlungs- und Lieferbedingungen werden bei einer Bestellung auf der Bestell-PDF abgebildet.",
            "payment_terms_tooltip_content": "Die mit dem Lieferunternehmen vereinbarten Zahlungs- und Lieferbedingungen werden bei einer Bestellung auf der Bestell-PDF abgebildet.",
            "vat_number_tooltip_content": "Die Umsatzsteuer-ID ist relevant für die Zuordnung einer importierten Rechnung zu dem Lieferunternehmen.",
            "name_tooltip_content": "Für die Zuordnung einer importierten Rechnung, den Namen des Lieferunternehmens genau so angeben, wie auf der Rechnung.",
            "tab_general": "Allgemein",
            "tab_delivery_info": "Lieferungsdaten",
            "tab_payment_info": "Zahlungsdaten",
            "tab_permissions": "Berechtigungsgruppen",
            "tab_departments": "Abteilungen",
            "tab_approvers": "Freigabe",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "name_length": "Darf höchstens 50 Zeichen enthalten.",
                "email_invalid": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
                "supplier_number_invalid": "Bitte geben Sie eine gültige Nummer an.",
                "vat_number_invalid": "Darf Zahlen, Groß- und Kleinbuchstaben, Punkte, Leerzeichen und Unterstriche enthalten. Darf höchstens 15 Zeichen enthalten.",
                "iban_invalid": "Darf mindestens 15, höchstens 34 Zeichen und keine Leerzeichen enthalten. Beginnt mit zwei Großbuchstaben, gefolgt von 2 Zahlen und 11 bis 30 Zahlen/Buchstaben.",
                "bic_invalid": "Darf mindestens 8, höchstens 11 Zeichen und nur Großbuchstaben und Zahlen enthalten. Die ersten sechs Zeichen müssen Buchstaben sein.",
                "external_id_length": "Die DATEV ID darf nur ganze Zahlen enthalten und nicht kleiner als 10000 sein."
            },
            "tour": {
                "name": "Geben Sie den Namen des Lieferunternehmens an.",
                "email": "Die E-mail-Adresse des Lieferunternehmens wird z.B. für die Vorbereitung von E-Mail-Nachrichten zur Beauftragung einer Bestellung verwendet.",
                "address": "Hier können Sie die Anschrift des Lieferunternehmens hinterlegen.",
                "external_id": "Die externe ID ist die Nummer des Unternehmens, wie sie z.B. in externer Software hinterlegt ist. Diese Nummer wird in der API verwendet.",
                "terms": "Erfassen Sie hier die mit dem Lieferunternehmen vereinbarten Zahlungs- und Lieferbedingungen. Diese Informationen werden dann bei einer Bestellung auf der Bestell-PDF abgebildet.",
                "btn_save": "Wenn Sie alle Informationen hinterlegt haben, speichern Sie das Lieferunternehmen hier."
            }
        }
    },
    "user": {
        "name": "Name",
        "client_id": "Hauptunternehmen",
        "approval_limit": "Freigabegrenze",
        "roles": {
            "user-admin": "Administrator",
            "user-price": "Preise sehen",
            "user-dashboard": "Dashboard sehen",
            "user-change-order": "Bestellung nachträglich bearbeiten",
            "user-see-orders": "Bestellungen sehen:",
            "user-see-all-orders": "Alle Unternehmen",
            "user-see-all-orders-belonging-to-own-company": "Eigenes  Unternehmen",
            "user-see-all-orders-belonging-to-own-department": "Eigene Abteilung",
            "user-see-own-orders-and-involved": "Eigene und mit Beteiligung",
            "user-see-all-invoices": "Alle Unternehmen",
            "user-see-invoices": "Rechnungen sehen:",
            "user-see-all-invoices-belonging-to-own-company": "Eigenes  Unternehmen",
            "user-see-own-invoices-and-involved": "Eigene und mit Beteiligung",
            "user-can-change-invoices": "Rechnung nachträglich bearbeiten",
            "user-see-client-addresses": "Alle Unternehmensanschriften sehen",
            "user-export-invoice": "Rechnungen an {export_name} übermitteln",
            "user-can-delete-document-directly": "Dokumente ohne Klärung stornieren/aussteuern",
            "user-can-insert-product": "Im Produktkatalog speichern"
        },
        "blocked": "Anmeldesperre",
        "token_issued_at": "API-Token erzeugt am",
        "form_sequence": "name,email,client_id,department_id,cost_center_id,location_id,approval_limit,roles,blocked",
        "page": {
            "tab_general": "Allgemein",
            "tab_roles": "Berechtigungen",
            "tab_roles_general": "Allgemeine Berechtigungen",
            "tab_roles_procurement": "Bestellanforderungen",
            "tab_roles_invoice": "Rechnungen",
            "tab_roles_block": "Sperren",
            "tab_permissions": "Berechtigungsgruppen",
            "tab_addresses": "Lieferanschriften",
            "tab_task_groups": "Aufgabengruppen",
            "tab_passkeys": "Passkeys",
            "new": "Neue Person",
            "block_btn": "Person sperren",
            "unblock_btn": "Person entsperren",
            "login_as_btn": "Einloggen als",
            "roles": "Berechtigungen",
            "no_permission": "Keine Berechtigungsgruppen ausgewählt.",
            "task_group_permissions": "Berechtigungen aus Aufgabengruppen",
            "approval_limit_tooltip_content": "Definieren Sie hier, welche Beträge durch diese Person final freigegeben werden können. <br> Höhere Beträge können freigegeben werden, müssen aber zusätzlich durch eine weitere Person mit höherer Freigabegrenze final freigegeben werden.",
            "approval_limit_placeholder": "Unbegrenzt",
            "upload_signature_btn": "Unterschrift auswählen",
            "upload_signature_tooltip_content": "Fügen Sie eine Unterschrift hinzu, welche auf das Beauftragungsdokument Ihrer freigegebenen Bestellungen gedruckt wird. <br/> Nehmen Sie am besten eine Bilddatei mit Hintergrund-Transparenz.",
            "api_token_btn": "API-Token erzeugen",
            "api_token_dialog_title": "API-Token erzeugen",
            "api_token_dialog_content": "API-Token werden für die Integration von Drittanwendungen benötigt und ermöglichen, im Namen der jeweiligen Person auf Daten zuzugreifen.",
            "api_token_dialog_date_placeholder": "Token-Ablaufdatum",
            "api_token_dialog_generate_btn": "Erzeugen",
            "api_token_dialog_copy_btn": "Kopieren",
            "api_token_dialog_copy_info": "Bitte kopieren und fügen Sie den folgenden API-Token in die Drittanwendung ein:",
            "api_token_dialog_copied": "Kopiert!",
            "language": "Sprache",
            "role_dashboard_tooltip": "Kann nur angehakt werden, wenn Person Preise sehen darf.",
            "passkey": "Passkey",
            "passkeys": "Passkeys",
            "passkey_name": "Name",
            "passkey_last_login": "Letzter Login",
            "passkey_dialog": {
                "title": "Passkey hinzufügen",
                "info1": "<b>Was sind Passkeys?</b><ul><li>Login ohne auf E-Mail zu warten</li><li>Mit Fingerabdruck, Ihrem Gesicht oder PIN</li><li>Sicher und komfortabel</li></ul>",
                "info2": "<b>Jetzt in 3 Schritten einrichten:</b><ol><li>Name eingeben z.B. \"Homeoffice PC\" oder \"Notebook\"</li><li>Auf \"Weiter\" klicken</li><li>Den Anweisungen Ihres Browsers / Betriebsystems folgen</li></ol>",
                "info3": "Eine Schritt-für-Schritt Anleitung <a href=\"https://liftbase.de/handbuch/anmeldung/passkeys/\" target=\"_blank\">finden Sie im Handbuch</a>",
                "name_input_label": "Passkey-Name",
                "name_input_placeholder": "z.B. 'Homeoffice PC' oder 'Notebook'",
                "added_msg": "Passkey wurde hinzugefügt.",
                "deleted_msg": "Passkey wurde gelöscht.",
                "exist_msg": "Sie haben bereits einen Passkey auf diesem Gerät, versuchen Sie es mit einem anderen Gerät."
            },
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "name_exists": "Der Name existiert bereits.",
                "email_required": "Bitte geben Sie eine E-Mail-Adresse ein.",
                "email_invalid": "Bitte geben Sie eine gültige E-Mail-Adresse ein.",
                "email_exists": "Die E-Mail-Adresse existiert bereits.",
                "approval_limit_length": "Die Freigabegrenze muss entweder größer als 0 oder leer sein."
            },
            "tour": {
                "name": "Geben Sie den Namen der Person an, am besten Vor- und Nachname.",
                "email": "Geben Sie die E-Mail-Adresse an, über die sich die Person einloggen kann.",
                "organization": "Ordnen Sie die Person einem Unternehmen, einer Abteilung und einer Kostenstelle zu. Diese Informationen werden dann beim Anlegen einer Bestellanforderung im Formular vorbelegt.",
                "approval_limit": "Hier können Sie der Person eine Freigabegrenze definieren. Wenn eine Bestellanforderung einen höheren Gesamtbetrag hat, muss diese zusätzlich von einer Person mit höherer Freigabegrenze freigegeben werden. Ist keine Grenze definiert, kann die Person alle Beträge final freigeben.",
                "signature": "Hier kann die Unterschrift der Person hinzugefügt werden. Gibt die Person eine Bestellanforderung frei, wird die Unterschrift zu der Bestell-PDF hinzugefügt.",
                "roles": "Hier können Berechtigungen der Person eingestellt werden, beispielsweise, ob und welche Vorgänge bzw. Informationen gesehen bzw. bearbeitet werden dürfen. Es gibt außerdem die Möglichkeit, die Person zu sperren, wenn diese bspw. das Unternehmen verlassen hat.",
                "roles_price": "Stellen Sie hier ein, ob die Person Produktpreise oder das Dashboard mit Auswertungen der Bestellanforderungen sehen darf.",
                "role_change_order": "Sie können einer vertrauenswürdigen Person die Berechtigung geben, Bestellanforderungen nach der Freigabe noch zu bearbeiten, ohne diese in die Klärung schicken zu müssen und somit eine erneute Freigabe zu vermeiden. Das kann beispielsweise nützlich sein, um Lieferanschriften oder Produktpreise nachträglich anzupassen.",
                "admin": "Wählen Sie aus, ob die Person Zugriff zum Administrationsbereich haben soll, um z.B. weitere Personen, Stammdaten oder Konfigurationen zu hinterlegen.",
                "block": "Wählen Sie diese Option, wenn eine Person daran gehindert werden soll, sich in liftbase einzuloggen, z.B. wenn sie das Unternehmen vorübergehend verlassen hat.",
                "api_token": "API-Token werden für die Integration von Drittanwendungen benötigt und ermöglichen, im Namen der jeweiligen Person auf Daten zuzugreifen.",
                "addresses": "Sie können für die Person eigene Lieferanschriften hinterlegen, z.B. wenn die Person von zuhause arbeitet und Produkte dorthin geliefert werden sollen.",
                "btn_save": "Speichern Sie die Person und die zugehörigen Informationen ab.",
                "profile_signature": "Hier können Sie Ihre Unterschrift hochladen. Wenn Sie dann Bestellanforderungen freigeben, wird Ihre Unterschrift auf die Bestell-PDF gedruckt.",
                "profile_addresses": "Sie können eigene Lieferanschriften hinterlegen, z.B. wenn Sie von zuhause arbeiten und Produkte dorthin geliefert werden sollen.",
                "profile_btn_save": "Speichern Sie die zugehörigen Informationen ab."
            }
        }
    },
    "lb_user": {
        "description": "Person"
    },
    "posting_key": {
        "name": "Bezeichnung",
        "tax": "Steuersatz in %",
        "number": "Schlüssel",
        "form_sequence": "name,number,tax",
        "page": {
            "new": "Neuer Steuerschlüssel",
            "validation": {
                "name_required": "Bitte geben Sie eine Bezeichnung ein.",
                "number_required": "Bitte geben Sie eine gültige Nummer an.",
                "number_length": "Darf bis zu 9-stellige Zahlen ab 1 enthalten."
            },
            "tour": {
                "name": "Geben Sie den Schlüssel sowie den zugehörigen Steuersatz an."
            }
        }
    },
    "ledger_account": {
        "name": "Bezeichnung",
        "description": "Beschreibung",
        "form_sequence": "name,number,description",
        "page": {
            "new": "Neue Sachkonto",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "number_required": "Bitte geben Sie eine gültige Nummer an.",
                "number_length": "Darf nur Zahlen enthalten und bei mehreren Ziffern nicht mit 0 beginnen.",
                "tax_required": "Bitte geben Sie einen gültigen Steuersatz aus.",
                "tax_length": "Prozentwert darf nur zweistellige Zahlen mit Komma und höchstens zwei Nachkommastellen enthalten."
            },
            "tour": {
                "name": "Geben Sie die Nummer sowie die Bezeichnung des Sachkontos an."
            }
        }
    },
    "profit_center": {
        "name": "Bezeichnung",
        "form_sequence": "name,number",
        "page": {
            "new": "Neues Profitcenter",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "number_required": "Bitte geben Sie eine gültige Nummer an.",
                "number_length": "Darf nur Zahlen enthalten und bei mehreren Ziffern nicht mit 0 beginnen.",
            },
            "tour": {
                "name": "Geben Sie die Nummer sowie die Bezeichnung des Profitcenters an."
            }
        }
    },
    "tax_group": {
        "code": "ID",
        "name": "Bezeichnung",
        "form_sequence": "name,code,client_id",
        "page": {
            "new": "Neue Steuergruppe",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "code_required": "Bitte geben Sie eine ID ein.",
                "client_required": "Bitte wählen Sie ein Unternehmen aus.",
            },
            "tour": {
                "name": "Geben Sie die ID sowie die Bezeichnung der Steuergruppe an."
            }
        }
    },
    "financial_dimension": {
        "code": "ID",
        "name": "Name",
        "form_sequence": "name,code",
        "page": {
            "new": "Neue Mitarbeitende",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "code_required": "Bitte geben Sie eine ID ein.",
                "client_required": "Bitte wählen Sie ein Unternehmen aus.",
            },
            "tour": {
                "name": "Geben Sie die ID sowie den Namen der Mitarbeidenden an."
            }
        }
    },
    "tax_item_group": {
        "code": "ID",
        "tax_code": "Steuer-ID",
        "name": "Bezeichnung",
        "form_sequence": "name,code,tax_code,client_id",
        "page": {
            "new": "Neue Steuer-Artikelgruppe",
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "code_required": "Bitte geben Sie eine ID ein.",
                "tax_code_required": "Bitte geben Sie eine Steuer-ID ein.",
                "client_required": "Bitte wählen Sie ein Unternehmen aus.",
            },
            "tour": {
                "name": "Geben Sie die ID sowie die Bezeichnung der Steuer-Artikelgruppe an."
            }
        }
    },
    "approver": {
        "description": "Freigabe",
        "label_log": "lb_user_name",
        "table_log": "lb_user",
        "approver_required": "Bitte wählen Sie eine Freigabe aus.",
        "is_required": "Pflicht",
        "is_required_info": "Muss auch freigeben, wenn vorherige Freigebende bereits über die notwendige Freigabegrenze verfügen."
    },
    "invoice": {
        "description": "Rechnung",
        "name": "Bezeichnung",
        "creator_id": "Hochgeladen von",
        "created_at": "Hochgeladen am",
        "number": "Rechnungsnummer",
        "invoice_number_formatted": "Interne RE-Nummer",
        "due_date": "Fälligkeit",
        "delivery_date": "Leistungsdatum",
        "invoice_date": "Belegdatum",
        "tax": "Steuersatz %",
        "total_brutto": "Gesamt-Brutto",
        "total_netto": "Gesamt-Netto",
        "total_netto_abbr": "-Netto",
        "fix_recognition": "Nicht zufrieden mit der Erkennung",
        "fix_recognition_checkbox": "Ich bin mit der Rechnungserkennung nicht zufrieden.",
        "paid": "Rechnung ist bereits bezahlt.",
        "currency": "Währung",
        "notes": "Notizen",
        "order_notes": "Bestellnotizen",
        "discount_percentage": "Skonto 1 - Prozent",
        "discount_amount": "Skonto 1 - Betrag",
        "discount_days": "Skonto 1 - Tage",
        "discount_payment_date": "Skonto 1 - Datum",
        "discount_percentage2": "Skonto 2 - Prozent",
        "discount_amount2": "Skonto 2 - Betrag",
        "discount_days2": "Skonto 2 - Tage",
        "discount_payment_date2": "Skonto 2 - Datum",
        "export_individual_items": "Rechnungspositionen übermitteln",
        "export_individual_items_tooltip": "Wenn dieser Haken gesetzt ist, werden die Positionen anstelle der Kopfdaten übermittelt.",
        "status": "Status",
        "statuses": {
            "open": "Rechnungserkennung",
            "export": "Bereit zum Export",
            "declined": "Storniert",
            "completed": "Exportiert",
            "approval": "Manuelle Prüfung",
            "clearing": "Klärung Buchhaltung",
            "accounting": "Buchhaltung"
        },
        "page": {
            "upload": {
                "title": "Neue Rechnungen hochladen",
                "info": "Drag-and-drop oder hier klicken zum Hochladen einer oder mehrerer Rechnungen (*.pdf bis zu 10MB).",
                "create_btn": "Rechnungserkennung starten",
                "file_invalid": "Bitte nur PDF-Dateien bis zu 10MB hochladen.",
                "tour": {
                    "before_attach": "Hier können Sie eine oder mehrere Rechnungen per Drag-and-drop oder Klicken in dem Bereich hochladen.",
                    "after_attach_title": "Rechnungserkennung starten",
                    "after_attach": "Hier können Sie die automatische Erkennung für alle hochgeladenen Rechnungen starten. Sie werden dann zur Übersicht der ersten Rechnung weitergeleitet. Die anderen Rechnungen finden Sie dann in der Aufgabeliste."
                }
            },
            "new": "Neue Rechnung",
            "payment_conditions": "Zahlungsbedingungen",
            "discount1": "Skonto 1",
            "discount2": "Skonto 2",
            "discount_percentage": "Prozent",
            "discount_amount": "Betrag",
            "discount_days": "Tage",
            "discount_payment_date": "Datum",
            "recognition_data_title": "Belegerkennung Rohdaten",
            "no_recognition_data": "Keine Daten erkannt.",
            "repeat_recognition_btn": "Belegerkennung wiederholen",
            "repeat_recognition_confirm_btn": "Bestätigen",
            "repeat_recognition_confirm_msg": "Die bestehenden Daten im Formular werden überschrieben. Sind Sie sicher, dass Sie fortfahren wollen?",
            "repeat_recognition_confirm_title": "Belegerkennung wiederholen",
            "repeat_recognition_feedback_msg": "Die Belegerkennung erfolgt im Hintergrund und kann einige Sekunden in Anspruch nehmen.",
            "recognized_client_id": "Anhand der <b>Umsatzsteuer-ID</b> und des <b>Namens</b> konnte das Unternehmen erfolgreich zugeordnet werden.<br>  <b>Unternehmen:</b> {client_name}",
            "no_client_recognized": "Es konnte anhand der <b>Umsatzsteuer-ID</b> oder des <b>Namens</b> kein Unternehmen zugeordnet werden.<br> Bitte überprüfen Sie, ob die Daten des Unternehmens in liftbase den Daten auf der Rechnung entsprechen.",
            "recognized_supplier_id": "Anhand der <b>Umsatzsteuer-ID</b> und des <b>Namens</b> konnte das Lieferunternehmen erfolgreich zugeordnet werden.<br> <b>Lieferunternehmen:</b> {supplier_name}",
            "no_supplier_recognized": "Es konnte anhand der <b>Umsatzsteuer-ID</b> oder des <b>Namens</b> kein Lieferunternehmen zugeordnet werden.<br> Bitte überprüfen Sie, ob die Daten des Lieferunternehmens in liftbase den Daten auf der Rechnung entsprechen.",
            "select_columns_btn": "Spalten auswählen",
            "export_to_datev_btn": "An DATEV übermitteln",
            "export_to_ms365_btn": "An D365 übermitteln",
            "export_btn_incomplete_form_tooltip": "Bitte vervollständigen Sie die Rechnungsdaten.",
            "export_btn_send_to_clearing_tooltip": "Zur erneuten Übertragung an D365, bitte über Schritt \"Klärung\" erneut zur Buchhaltung schicken.",
            "export_error": "<b>Die Rechnung konnte nicht übermittelt werden</b>.<br>",
            "no_export_config_tooltip": "Es wurde keine Verbindung zu {export_name} eingerichtet.<br>Wenden Sie sich ggf. an eine Person mit Administrations-Rechten, <br>um die Verbindung im Administrations-Bereich einzurichten.",
            "confirm_before_leave_msg": "Ihre Rechnung wurde nicht gespeichert. Sind Sie sicher, dass Sie die Seite verlassen wollen?",
            "can_not_edit": "Ihnen fehlt die Berechtigung zu Bearbeitung der Daten. Bitte senden Sie die Rechnung über die Aktionen zur Klärung, um Änderungen an den Daten vorzunehmen.",
            "total_brutto_missing": "Bitte geben Sie das Gesamt-Brutto ein.",
            "total_brutto_zero": "Darf keinen Betrag von 0€ enthalten.",
            "total_brutto_does_not_match_with_positions": "Gesamtbeträge müssen der Summe der Positionen entsprechen.",
            "combination_invalid": "Die Kombination aus Sachkonto, Kostenstelle und Profitcenter ist in D365 nicht zulässig.",
            "possible_combinations": "Mögliche Kombinationen",
            "current_combination": "Aktuelle Kombination",
            "combination": "Kombination",
            "lines": {
                "add_tooltip": "Rechnungsposition hinzufügen",
                "get_from_recognition": "Erkannte Positionen laden",
                "legend": {
                    "description": "Legende",
                    "no_order": "Artikel kommt in der Bestellung nicht vor.",
                    "variance": "Abweichung in markierten Spalten.",
                    "not_invoiced": "Bestellter Artikel wurde auf der Rechnung nicht gefunden.",
                    "matched": "Artikel auf Bestellung und Rechnung ist identisch."
                },
                "form": {
                    "item": "Artikel",
                    "new": "Neue Rechnungsposition",
                    "validation": {
                        "description_required": "Bitte geben Sie einen Namen ein.",
                        "item_number_required": "Bitte geben Sie eine Artikelnummer ein.",
                        "tax_percentage_required": "Bitte geben Sie eine gültige Steuer aus.",
                        "unit_net_price_required": "Bitte geben Sie eine gültige Nummer an.",
                        "quantity_required": "Bitte geben Sie eine gültige Nummer an."
                    },
                    "tour": {
                        "name": "Geben Sie einen aussagekräftigen Namen für das Produkt an.",
                        "number": "Hier können Sie die Artikelnummer des Produkts erfassen.",
                        "price": "Geben Sie einen Steuersatz sowie einen Netto-Preis an. Der Brutto-Preis wird dann mit dem Steuersatz berechnet."
                    }
                },
                "table": {
                    "source": "Quelle",
                    "item_number": "Art. Nr.",
                    "name": "Name",
                    "quantity": "Anz.",
                    "tax": "Steuer (%)",
                    "netto_price": "Einzel-Netto (€)",
                    "total_netto_price": "Gesamt-Netto (€)",
                    "tax_amount": "Gesamt-Steuer (€)",
                    "total_brutto_price": "Gesamt-Brutto (€)",
                    "cost_center": "Kostenstelle",
                    "profit_center": "Profitcenter",
                    "ledger_account": "Sachkonto",
                    "tax_group": "Steuergruppe",
                    "tax_item_group": "Steuer-Artikelgruppe",
                    "open_bcase": "Dokument anzeigen",
                    "no_order_position": "Keine Bestellpositionen gefunden. Bitte ordnen Sie der Rechnung <b>eine BANF und ein Lieferunternehmen</b> zu.",
                    "copy_from_procurement": "Alle Artikel der Bestellung einfügen",
                    "no_order_tooltip": "Noch keine Bestellanforderung verknüpft.",
                    "no_supplier_tooltip": "Noch kein Lieferunternehmen verknüpft.",
                    "validation_error": "Bitte korrigieren Sie die Fehler in der Tabelle."
                }
            },
            "export_dialog": {
                "title": {
                    "D365": "Journal erstellen",
                    "DATEV": "An DATEV übermitteln",
                },
                "description": "Die folgenden Belege werden an {export_name} übermittelt.",
                "btn_confirm": "Übermitteln",
                "btn_finish": "Schließen",
                "export_feedback": "Übermittelt",
                "exported": "Übermittelt",
                "continue_background": "Im Hintergrund fortfahren",
                "exported_at": "Zuletzt exportiert",
                "is_loading": "Übermittlung läuft"
            },
            "select_columns_dialog": {
                "title": "Spalten auswählen",
                "select_fields": "Die folgenden Spalten werden unter <i>Meine Aufgaben</i> und <i>Alle Belege</i> angezeigt.",
                "btn_confirm": "Anwenden",
                "btn_finish": "Schließen",
            },
            "copy_procurement_dialog": {
                "title": "Daten übernehmen",
                "message": "Möchten Sie einige Daten der Bestellung in diese Rechnung übernehmen?",
                "overwrite_checkbox": "Die bestehenden Daten im Formular überschreiben.",
                "select_supplier_msg": "Wählen Sie aus, von welchem Lieferunternehmen Sie die Daten kopieren möchten:",
                "select_supplier_placeholder": "Lieferunternehmen auswählen",
                "confirm_btn": "Bestätigen"
            },
            "validation": {
                "invoice_date_required": "Bitte geben Sie ein Rechnungsdatum ein.",
                "due_date_required": "Bitte geben Sie eine Fälligkeit ein.",
                "due_date_invalid1": "Fälligkeitsdatum darf nicht vor dem Rechnungsdatum liegen.",
                "delivery_date_required": "Bitte geben Sie ein Leistungsdatum ein.",
                "ledger_account_required": "Bitte wählen Sie eine Sachkonto aus.",
                "branch_required": "Bitte wählen Sie eine Niederlassung aus.",
                "department_required": "Bitte wählen Sie eine Abteilung aus.",
                "posting_key_required": "Bitte wählen Sie einen Steuerschlüssel aus.",
                "supplier_required": "Bitte wählen Sie ein Lieferunternehmen aus.",
                "cost_center_required": "Bitte wählen Sie eine Kostenstelle aus.",
                "profit_center_required": "Bitte wählen Sie eine Profitcenter aus.",
                "employee_required": "Bitte wählen Sie eine Mitarbeitende aus.",
                "d365_department_required": "Bitte wählen Sie eine D365 Abteilung aus.",
                "financial_dimension_required": "Bitte wählen Sie eine Finanzdimension aus.",
                "client_required": "Bitte wählen Sie ein Unternehmen aus.",
                "number_required": "Bitte geben Sie die Rechnungsnummer ein.",
                "number_invalid": "Darf höchstens 36 Zahlen, Groß- und Kleinbuchstaben, keine Umlaute und die Zeichen $ % & * + - / enthalten.",
                "notes_length": "Darf höchstens 120 Zeichen enthalten.",
                "discount_percentage_invalid1": "Skontosatz muss größer als 0 sein.",
                "discount_percentage_invalid2": "Skonto 1 muss größer als Skonto 2 sein.",
                "discount_amount_invalid1": "Nur positives Skonto ist für positive Rechnungsbeträge zulässig.",
                "discount_amount_invalid2": "Nur negatives Skonto ist für negative Rechnungsbeträge zulässig.",
                "discount_amount_invalid3": "Skonto 1 muss größer als Skonto 2 sein.",
                "discount_amount_invalid4": "Skonto muss kleiner als der Gesamtbetrag sein.",
                "discount_amount_invalid5": "Die Summe der Skontobeträge muss kleiner als der Gesamtbetrag sein.",
                "discount_date_invalid1": "Skonto 1 und 2 müssen zw. Beleg- und Fälligkeitsdatum liegen.",
                "discount_date_invalid2": "Skonto 1 - Datum muss vor Skonto 2 - Datum liegen.",
                "missing_discount_field": "Alle Skonto-Felder müssen ausgefüllt sein."
            }
        }
    },
    "invoice_line": {
        "item_number": "Artikelnummer",
        "quantity": "Menge",
        "unit_net_price": "Netto-Preis",
        "tax_percentage": "Steuer"
    },
    "location": {
        "name": "Name",
        "number": "Nummer",
        "address": "Anschrift",
        "form_sequence": "name,number,address"
    },
    "spreadsheet": {
        "add_row": "Neue Zeile",
        "download": "Tabelle herunterladen",
        "save": "Tabelle speichern"
    },
    "audit_log": {
        "description": "Bearbeitungen",
        "history": "Bearbeitungen",
        "table": "Tabelle",
        "lb_user_id": "Benutzer",
        "data_before": "Bevor",
        "data_after": "Nachher",
        "created_at": "Datum",
        "type": {
            "insert": "Hinzugefügt",
            "update": "Bearbeitet",
            "delete": "Entfernt"
        },
        "msg": {
            "deleted": "entfernt",
            "inserted": "hinzugefügt",
            "was_deleted": "Wurde entfernt.",
            "was_inserted": "Wurde hinzugefügt."
        },
        "none": "kein(e)",
        "empty": "Keine Bearbeitungen vorhanden."
    },
    "txt_before_leave_page": "Ihre Änderungen werden eventuell nicht gespeichert. Sind Sie sicher, dass Sie fortfahren wollen?",
    "easybill_invoice": {
        "from_tenant": "von Tenant",
        "no_invoice": "Noch keine Rechnung erstellt.",
        "number": "Nummer",
        "service_date": "Zeitraum",
        "from": "Vom",
        "until": "bis",
        "total": "Gesamt",
        "due_date": "Fälligkeit",
        "status": "Status",
        "paid_at": "Bezahlt am",
        "payment_missing": "Ausstehend",
        "sepa": "SEPA",
        "tour": {
            "invoice_table": "Hier sehen Sie eine detaillierte Übersicht über alle liftbase-Rechnungen inklusive Status. Die Rechnungen können über einen Button herunter geladen werden."
        }
    },
    "configuration": {
        "description": "Konfiguration",
        "sepa_number": "Gläubiger-Identifikationsnummer",
        "sepa_mandate_reference": "Mandatsreferenz",
        "remove_sepa_msg": "Sind Sie sicher, dass Sie die SEPA Lastschrift widerrufen wollen?', 'SEPA Lastschrift widerrufen",
        "remove_sepa_btn": "Widerrufen",
        "validation": {
            "bic_invalid": "Bitte geben Sie einen gültigen BIC ein.",
            "iban_invalid": "Bitte geben Sie eine gültige IBAN-Nummer ein."
        },
        "datev": {
            "description": "DATEV",
            "connection": "Verbindung mit DATEV",
            "status_active": "aktiv",
            "status_inactive": "inaktiv",
            "from": "von",
            "until": "bis",
            "load_clients": "DATEV Unternehmen laden",
            "remove_connection": "Verbindung entfernen",
            "add_connection": "Verbindung einrichten",
            "connected_by": "Verbindung von {name} ist hergestellt.",
            "apps_manager": "Hier können Sie die <a href=\"https://apps.datev.de/tokrevui\" target=\"_blank\">mit DATEV verbundenen Anwendungen</a> verwalten.",
            "no_client_found": "Keine DATEV Unternehmen gefunden.",
            "msg_no_clients": "Keine Einträge gefunden oder Sie haben keine nötigen Berechtigungen oder die Verbindung muss erneut eingerichtet werden.",
            "fiscal_year_start": "Geschäftsjahr",
            "account_length": "Kontolänge",
            "is_accounts_payable_ledger_available": "Rechnungseingang",
            "is_accounts_receivable_ledger_available": "Rechnungsausgang",
            "client_number": "Unternehmensnummer",
            "consultant_number": "Beraternummer",
            "client_name": "Unternehmen",
            "liftbase_client": "liftbase Unternehmen",
            "save_client": "Unternehmen speichern",
            "change_client": "Unternehmen ändern"
        },
        "ms365": {
            "description": "D365",
            "connection": "Verbindung mit D365",
            "status_active": "aktiv",
            "status_inactive": "inaktiv",
            "connect_and_load_data": "Verbindung einrichten und Daten laden",
            "connect": "Verbindung einrichten",
            "load_data": "Alle Daten laden",
            "remove_connection": "Verbindung entfernen",
            "add_connection": "Verbindung einrichten",
            "no_client_found": "Keine D365 Mandanten wurden gefunden.",
            "msg_no_clients": "Keine Einträge gefunden oder Sie haben keine nötigen Berechtigungen oder die Verbindung muss erneut eingerichtet werden.",
            "client_number": "D365 Unternehmennummer",
            "client_name": "D365 Unternehmen",
            "liftbase_client": "liftbase Unternehmen",
            "save_client": "Unternehmen speichern",
            "change_client": "Unternehmen ändern",
            "sync_running": "Die Daten werden geladen. Der Vorgang kann einige Minuten dauern.",
            "last_sync_at": "Zuletzt geladen am {last_sync_at}",
            "journal_name": "Journal Name",
            "org_url": "Org URL",
        },
        "template": {
            "module-procurement": {
                "group": "Module",
                "label": "Bestellanforderungen",
                "tour": [
                    {
                        "target": ".step-modules",
                        "content": "<p>Bitte wählen die liftbase Module aus, die Sie aktivieren möchten. Informationen zu den Kosten finden Sie auf <a href=\"liftbase.de/preise\" target=\"_blank\">liftbase.de/preise</a>.</p>"
                    }
                ]
            },
            "module-invoice": {
                "group": "Module",
                "label": "Rechnungen"
            },
            "module-permissions": {
                "group": "Module",
                "label": "Berechtigungsgruppen"
            },
            "module-multilingualism": {
                "group": "Module",
                "label": "Mehrsprachige Stammdaten"
            },
            "module-invoice-export": {
                "group": "Module",
                "label": "Rechnungsexport an",
                "options": [
                    {
                        "value": "datev",
                        "content": "DATEV"
                    },
                    {
                        "value": "ms365",
                        "content": "D365"
                    }
                ]
            },
            "procurement-select-product": {
                "group": "Rollenvorbelegungen",
                "label": "Person „Produktauswahl“",
                "tour": [
                    {
                        "target": ".step-assignment",
                        "content": "<p>Hier können globale Rollenvorbelegungen hinterlegt werden. Sind keine Rollenvorbelegungen auf Ebene des Unternehmens oder der Abteilung definiert, übernehmen die hier eingetragenen Personen die jeweiligen Schritte im Ablauf der Bestellanforderungen und Rechnungen.</p>"
                    }
                ]
            },
            "procurement-send-order": {
                "group": "Rollenvorbelegungen",
                "label": "Person „Beauftragung“"
            },
            "procurement-clearing": {
                "group": "Rollenvorbelegungen",
                "label": "Person „Klärung Einkauf“",
                "hint": "Für die Klärung in Bestellabläufen."
            },
            "procurement-consignee": {
                "group": "Rollenvorbelegungen",
                "label": "Person „Wareneingang“"
            },
            "invoice-manual-check": {
                "group": "Rollenvorbelegungen",
                "label": "Person „Manuelle Prüfung (Rechnung)“"
            },
            "invoice-clearing": {
                "group": "Rollenvorbelegungen",
                "label": "Person „Klärung Buchhaltung“",
                "hint": "Für die Klärung während der Rechnungsablage."
            },
            "procurement-init-seq": {
                "group": "Bestellanforderungen",
                "label": "Bestellnummern ab",
                "hint": "Geben Sie hier eine Zahl an, ab der die Bestellnummern starten sollen.",
                "validation": {
                    "interval": "Muss zwischen 0 und 999999999 liegen.",
                    "type": "Darf nur ganze Zahlen enthalten."
                },
                "tour": [
                    {
                        "target": ".step-procurement",
                        "content": "Hier finden Sie Einstellungen an den Bestellanforderungen. Stellen Sie z.B. ein mit welcher Nummer die Bestellanforderungen starten sollen und ob alle Produkte in den Bestellanforderungen einer Warengruppe zugeordnet werden müssen."
                    }
                ]
            },
            "procurement-require-product-group": {
                "group": "Bestellanforderungen",
                "label": "Alle Produkte müssen einer Warengruppe zugeordnet sein."
            },
            "procurement-require-product-selection": {
                "group": "Bestellanforderungen",
                "label": "Bestellanforderungsprozess ",
                "options": [
                    {
                        "value": "true",
                        "content": "mit Produktauswahl<br><ul><li>BANF erfassen</li><li>Produktauswahl</li><li>Freigabe</li><li>Beauftragung</li><li>Wareneingang</li></ul>"
                    },
                    {
                        "value": "false",
                        "content": "ohne Produktauswahl<br><ul><li>BANF erfassen</li><li>Freigabe</li><li>Beauftragung</li><li>Wareneingang</li></ul>"
                    }
                ]
            },
            "email-send-order": {
                "group": "Bestellungen",
                "label": "E-Mail-Bestellung",
                "hint": "E-Mail-Anschreiben, welches im Schritt der Beauftragung inklusive der Bestell-PDF generiert und an das Lieferunternehmen gesendet werden kann.",
                "default_value": "Sehr geehrte Damen und Herren,<br>anbei sende ich Ihnen unsere Bestellung als PDF. Bitte senden Sie uns eine Auftragsbestätigung zu.",
                "tour": [
                    {
                        "target": ".step-email-order-free-text",
                        "content": "Verfassen Sie hier einen Text, welcher in dem E-Mail-Anschreiben abgebildet wird, welches im Schritt der Beauftragung inklusive der Bestell-PDF generiert und an das Lieferunternehmen gesendet werden kann."
                    },
                    {
                        "target": ".step-pdf-free-text",
                        "content": "Verfassen Sie hier einen Text, welcher im Schritt der Beauftragung auf der generierten Bestell-PDF abgebildet wird, die anschließend an das Lieferunternehmen gesendet werden kann."
                    }
                ]
            },
            "procurement-pdf-free-text": {
                "group": "Bestellungen",
                "label": "Bestell-PDF",
                "hint": "Anschreiben, welches im Schritt der Beauftragung auf der generierten Bestell-PDF abgebildet wird, die anschließend an das Lieferunternehmen gesendet werden kann.",
                "default_value": ""
            },
            "invoice-recognition-lines": {
                "group": "Rechnungen",
                "label": "Rechnungspositionen aus Belegerkennung automatisch erstellen."
            },
            "invoice-procurement-required": {
                "group": "Rechnungen",
                "label": "Verknüpfung der Bestellanforderung erforderlich.",
            },
            "notifications-mail-interval": {
                "group": "Benachrichtigungen",
                "label": "E-Mail-Intervall in Minuten",
                "tour": [
                    {
                        "target": ".step-notification",
                        "content": "Stellen Sie hier global ein, wie oft Personen maximal über neue Aufgaben per E-Mail informiert werden sollen. E-Mail-Benachrichtigungen werden nur gesendet, wenn eine Person eine neue Aufgabe erhalten hat."
                    }
                ]
            },
            "punchout-amazon-identity": {
                "group": "Integrationen",
                "subgroup": "Punch-out Amazon",
                "label": "Identity",
                "tour": [
                    {
                        "target": ".step-punchout-amazon",
                        "content": "Hier können Sie Ihren Zugang zu integrierten Marktplätzen wie Amazon und Mercateo einrichten. Eine Anleitung dazu finden Sie <a href=\"https://liftbase.de/handbuch/einrichtung/punch-out\" target=\"_blank\">in unserem Handbuch</a>."
                    }
                ]
            },
            "punchout-amazon-secret": {
                "group": "Integrationen",
                "subgroup": "Punch-out Amazon",
                "label": "Secret"
            },
            "punchout-amazon-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out Amazon",
                "label": "Punch-out-URL"
            },
            "punchout-amazon-order-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out Amazon",
                "label": "Bestell-URL"
            },
            "punchout-amazon-email": {
                "group": "Integrationen",
                "subgroup": "Punch-out Amazon",
                "label": "E-Mail",
                "hint": "Die E-Mail-Adresse des Amazon-Hauptaccounts. <br>Leer lassen, um E-Mail-Adresse der angemeldeten Person zu übermitteln. <br>Erfordert ggf. Amazon-Account pro Person."
            },
            "punchout-amazon-supplier": {
                "group": "Integrationen",
                "subgroup": "Punch-out Amazon",
                "label": "Standard-Lieferunternehmen",
                "hint": "Zu welchem Lieferunternehmen sollen Bestellungen dieses Punch-out Anbieters zugeordnet werden (z. B. in Auswertungen)?"
            },
            "punchout-mercateo-identity": {
                "group": "Integrationen",
                "subgroup": "Punch-out Mercateo",
                "label": "Identity"
            },
            "punchout-mercateo-secret": {
                "group": "Integrationen",
                "subgroup": "Punch-out Mercateo",
                "label": "Secret"
            },
            "punchout-mercateo-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out Mercateo",
                "label": "Punch-out-URL"
            },
            "punchout-mercateo-order-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out Mercateo",
                "label": "Bestell-URL"
            },
            "punchout-mercateo-email": {
                "group": "Integrationen",
                "subgroup": "Punch-out Mercateo",
                "label": "E-Mail",
                "hint": "Die E-Mail-Adresse des Mercateo-Hauptaccounts. <br>Leer lassen, um E-Mail-Adresse der angemeldeten Person zu übermitteln. <br>Erfordert ggf. Mercateo-Account pro Person."
            },
            "punchout-mercateo-supplier": {
                "group": "Integrationen",
                "subgroup": "Punch-out Mercateo",
                "label": "Standard-Lieferunternehmen",
                "hint": "Zu welchem Lieferunternehmen sollen Bestellungen dieses Punch-out Anbieters zugeordnet werden (z. B. in Auswertungen)?"
            },
            "punchout-rajapack-identity": {
                "group": "Integrationen",
                "subgroup": "Punch-out RAJA",
                "label": "Identity"
            },
            "punchout-rajapack-secret": {
                "group": "Integrationen",
                "subgroup": "Punch-out RAJA",
                "label": "Secret"
            },
            "punchout-rajapack-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out RAJA",
                "label": "Punch-out-URL"
            },
            "punchout-rajapack-order-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out RAJA",
                "label": "Bestell-URL"
            },
            "punchout-rajapack-email": {
                "group": "Integrationen",
                "subgroup": "Punch-out RAJA",
                "label": "E-Mail"
            },
            "punchout-rajapack-supplier": {
                "group": "Integrationen",
                "subgroup": "Punch-out RAJA",
                "label": "Standard-Lieferunternehmen",
                "hint": "Zu welchem Lieferunternehmen sollen Bestellungen dieses Punch-out Anbieters zugeordnet werden (z. B. in Auswertungen)?"
            },
            "punchout-conrad-identity": {
                "group": "Integrationen",
                "subgroup": "Punch-out Conrad",
                "label": "Identity"
            },
            "punchout-conrad-secret": {
                "group": "Integrationen",
                "subgroup": "Punch-out Conrad",
                "label": "Secret"
            },
            "punchout-conrad-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out Conrad",
                "label": "Punch-out-URL"
            },
            "punchout-conrad-order-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out Conrad",
                "label": "Bestell-URL"
            },
            "punchout-conrad-email": {
                "group": "Integrationen",
                "subgroup": "Punch-out Conrad",
                "label": "E-Mail"
            },
            "punchout-conrad-supplier": {
                "group": "Integrationen",
                "subgroup": "Punch-out Conrad",
                "label": "Standard-Lieferunternehmen",
                "hint": "Zu welchem Lieferunternehmen sollen Bestellungen dieses Punch-out Anbieters zugeordnet werden (z. B. in Auswertungen)?"
            },
            "punchout-thgeyer-identity": {
                "group": "Integrationen",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Identity"
            },
            "punchout-thgeyer-secret": {
                "group": "Integrationen",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Secret"
            },
            "punchout-thgeyer-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Punch-out-URL"
            },
            "punchout-thgeyer-supplier": {
                "group": "Integrationen",
                "subgroup": "Punch-out TH.GEYER",
                "label": "Standard-Lieferunternehmen",
                "hint": "Zu welchem Lieferunternehmen sollen Bestellungen dieses Punch-out Anbieters zugeordnet werden (z. B. in Auswertungen)?"
            },
            "punchout-reichelt-identity": {
                "group": "Integrationen",
                "subgroup": "Punch-out Reichelt",
                "label": "Identity"
            },
            "punchout-reichelt-secret": {
                "group": "Integrationen",
                "subgroup": "Punch-out Reichelt",
                "label": "Secret"
            },
            "punchout-reichelt-url": {
                "group": "Integrationen",
                "subgroup": "Punch-out Reichelt",
                "label": "Punch-out-URL"
            },
            "punchout-reichelt-supplier": {
                "group": "Integrationen",
                "subgroup": "Punch-out Reichelt",
                "label": "Standard-Lieferunternehmen",
                "hint": "Zu welchem Lieferunternehmen sollen Bestellungen dieses Punch-out Anbieters zugeordnet werden (z. B. in Auswertungen)?"
            },
            "tour": [
                {
                    "target": "",
                    "content": ""
                }
            ]
        },
        "tour": {
            "tab_general": "Hier kann der Name Ihres Unternehmens für die liftbase-Umgebung angegeben werden.",
            "tab_contact": "Geben Sie hier die Kontaktdaten Ihres Unternehmens an. Die Daten werden z.B. für die Aus- und Zustellung der Rechnungen für die liftbase-Nutzung benötigt.",
            "tab_bank": "Hier können Sie für die Abrechnung von liftbase eine SEPA-Lastschrift hinterlegen und ggf. widerrufen.",
            "tab_datev": "Hier können Sie die Integration mit DATEV einrichten. Melden Sie sich zuerst bei DATEV an, um alle verfügbaren Unternehmen zu laden. Anschließend ordnen Sie den Unternehmen aus DATEV ein Unternehmen aus liftbase zu und stellen die Verbindung für jedes Unternehmen einzeln her, um den Export zu ermöglichen. Die Verbindung der Unternehmen hat eine Gültigkeit von 2 Jahren und verlängert sich jedes Mal, wenn sie für einen Export verwendet wird.",
        },
        "mailbox": {
            "description": "Postfach",
            "description_plural": "Postfächer",
            "name": "Postfach-Name",
            "username": "E-Mail-Adresse",
            "password": "Passwort",
            "host": "Host",
            "protocol": "Protokoll",
            "folder": "Ordner",
            "mailbox": "MailBox",
            "period_in_days": "Zeitraum in Tagen",
            "period_in_days_column": "Zeitraum (Tage)",
            "period_in_days_tooltip": "Alter der E-Mails in Tagen",
            "remove_mailbox_msg": "Sind Sie sicher, dass Sie das Postfach entfernen wollen?",
            "remove_mailbox_btn": "Entfernen",
            "import_invoices_automatically": "Rechnungsimport aktiv",
            "check_connection_btn": "Verbindung testen",
            "connectivity_success_msg": "Die Verbindung wurde erfolgreich hergestellt.",
            "connectivity_error_msg": "Die Verbindung konnte nicht hergestellt werden. Bitte prüfen Sie die Einstellungen.",
            "save_and_connect_btn": "Speichern und verbinden",
            "disconnect_btn": "Verbindung trennen",
            "disconnect_success_msg": "Die Verbindung wurde erfolgreich getrennt."
        }
    },
    "error_title": "Fehler",
    "request_error": "Fehler beim ausführen der Anfrage. Bitte prüfen Sie Ihre Internet-Verbindung oder versuchen Sie es später erneut.",
    "saved_success": "Das Dokument wurde gespeichert.",
    "save_error": "Es ist ein Fehler beim Speichern aufgetreten.",
    "help_page": {
        "title": "Hilfe",
        "popup_feedback_msg": "Ihr Browser ist richtig eingerichtet. Sie können dieses Fenster jetzt schließen.",
        "punchout_error_msg": "Es ist ein Fehler aufgetreten. Bitte prüfen Sie die Punch-out-Konfigurationen oder wenden sich an eine Person mit Administratorenrechten.",
        "popup_did_not_open": "Wurde soeben kein neues Fenster geöffnet, sind Pop-ups in Ihrem Browser blockiert. Es kann daher bei der Anwendung zu Einschränkungen kommen.",
        "test_email_msg": "Es sollte sich ein E-Mail-Programm oder eine Liste zur Auswahl von Programmen geöffnet haben. Richten Sie sich ggf. ein E-Mail-Programm ein, welches Sie in der Liste auswählen können.",
        "tour_restart": "Die Tour wurde neu gestartet.",
        "manual_title": "Handbuch",
        "manual_msg": "<p>Im Handbuch finden Sie verschiedene Artikel und Anleitungen über die Verwendung und Einrichtung von liftbase.</p>",
        "manual_open_btn": "Handbuch öffnen",
        "function_title": "Testfunktionen",
        "popup_msg": "<p>Hier können Sie testen, ob in Ihrem Browser Pop-up-Fenster zugelassen sind, die für Ihre Produktauswahl bei den externen Marktplätzen von Amazon und Mercateo benötigt werden.</p>",
        "popup_test_btn": "Pop-up-Blocker testen",
        "email_msg": "<p>Hier können Sie testen, ob Sie E-Mails mit einem E-Mail-Programm öffnen können. Die E-Mail-Funktion wird z. B. verwendet, um Bestellungen an Lieferunternehmen zu senden.</p>",
        "email_test_btn": "E-Mail-Funktion testen",
        "tour_title": "Tour",
        "tour_msg": "<p>Um eine erneute Einführung in liftbase zu erhalten, können Sie hier die Tour neu starten.</p>",
        "tour_btn": "Neu starten"
    },
    "dashboard_page": {
        "no_permission": "Sie haben keine Berechtigung für das Dashboard.",
        "no_option": "Kein",
        "title": "Dashboard",
        "date_picker": {
            "start_placeholder": "Startdatum",
            "end_placeholder": "Startdatum",
            "separator": "bis"
        },
        "ordered_by": "Beauftragt von",
        "count_by_status": "Anzahl nach Status",
        "diagrams": {
            "title": "Diagramme",
            "count_by_status": "Anzahl je Status",
            "value_by_status": "Beträge je Status (Netto in EUR)",
            "count_by_department": "Anzahl je Abteilung",
            "value_by_department": "Beträge je Abteilung (Netto in EUR)",
            "count_by_cost_center": "Anzahl je Kostenstelle",
            "value_by_cost_center": "Beträge je Kostenstelle (Netto in EUR)",
            "count_by_ledger_account": "Anzahl je Sachkonto",
            "value_by_ledger_account": "Beträge je Sachkonto (Netto in EUR)"
        },
        "budgets": {
            "title": "Budgets und Ausgaben",
            "approved_status": "Freigegeben",
            "in_approval_status": "In Freigabe",
            "open_status": "Offen",
            "no_budget": "Ohne Budget",
            "from": "Ab",
            "no_name": "Ohne Name"
        },
        "products": {
            "title": "Produkte",
            "approved_status": "Freigegeben",
            "in_approval_status": "In Freigabe"
        },
        "no_data": "Keine Bestellanforderungen gefunden.",
        "from_budget": "vom Budget",
        "data": "Ausgaben",
        "shortcuts": {
            "this_week": "Diese Woche",
            "last_week": "Letzte Woche",
            "this_month": "Dieser Monat",
            "last_month": "Letzter Monat",
            "last_3_months": "Letzte 3 Monate",
            "last_6_months": "Letzte 6 Monate",
            "this_year": "Dieses Jahr",
            "last_year": "Letztes Jahr"
        },
        "tour": {
            "title": "Das Dashboard bietet Ihnen Statistiken und Auswertungsmöglichkeiten zu Ihren Bestellanforderungen.",
            "filters_title": "Filtern",
            "filters": "Wählen Sie dazu zunächst einen gewünschten Zeitraum aus und filtern Sie die auszuwertenden Bestellanforderungen nach Unternehmen, Bestellperson, Kostenstelle oder Abteilung.",
            "count_by_status_title": "Anzahl nach Status",
            "count_by_status": "Hier sehen Sie, wie viele der vorgefilterten Bestellanforderungen sich in den verschiedenen Status befinden.",
            "graphic_title": "Diagramme",
            "graphic": "Hier sehen Sie eine graphische Darstellung der Anzahl oder Beträge der Bestellanforderungen, jeweils nach Status, Abteilungen oder Kostenstellen gruppiert.",
            "budget_title": "Budgets und Ausgaben",
            "budget": "Dieser Bereich liefert Ihnen eine Übersicht über die Budgets und Ausgaben Ihrer Kostenstellen. Anhand der farbigen Balken sehen Sie, wie der Verbrauch der Budgets ist und wie viele der Ausgaben sich noch in der Freigabe befinden.",
            "progress_bar_cost_center_title": "Balken nach Kostenstelle",
            "progress_bar_cost_center": "Hier können Sie eine detailliertere Übersicht zu den verschiedenen Budgets einer Kostenstelle aufklappen. Zu den einzelnen Budgets können außerdem die getätigten Bestellungen aufgelistet werden. Ausgaben ohne Budget sind unter einem gesonderten Balken gelistet."
        }
    },
    "overview_page": {
        "title": "Übersicht",
        "procurement_abbr": "BE",
        "invoice_abbr": "RE",
        "draft_abbr": "EN",
        "delivery_note_abbr": "LS",
        "all_docs": "Alle Belege",
        "my_tasks": "Meine Aufgaben",
        "procurements": "Bestellanforderungen",
        "invoices": "Rechnungen",
        "drafts": "Entwürfe",
        "no_selection": "Kein Dokument ausgewählt.",
        "comment_box_placeholder": "Ihr Kommentar...",
        "comment_box_btn": "Kommentieren",
        "duplicate_invoice": {
            "message": "Dublette - Es wurde mindestens eine Rechnung mit gleicher Rechnungsnummer gefunden:",
            "invoice_number": "Belegnummer",
            "invoice_date": "Datum",
        },
        "task_list": {
            "name": "Bezeichnung",
            "status": "Status",
            "date": "Datum",
            "draft": "Entwurf"
        },
        "searchbar": {
            "search": "Suche",
            "filter_by": "Filter nach",
            "created_at": "Erstelldatum",
            "timeline": "Timeline",
            "last_used": "Zuletzt verwendet",
            "favorites": "Favoriten",
            "no_search": "Keine",
            "timeline_props": {
                "editor_name": "Bearbeiter*in",
                "entry_type": "Timeline-Schritt",
                "current_editor_name": "Aktuelle*r Bearbeiter*in",
                "current_entry_type": "Aktueller Schritt"
            },
            "procurement_props": {
                "order_number_formatted": "Bestellnummer",
                "name": "Bezeichnung",
                "creator_name": "Erstellt von",
                "client_name": "Unternehmen",
                "department_name": "Abteilung",
                "cost_center_name": "Kostenstellen-Name",
                "cost_center_number": "Kostenstellen-Nummer",
                "consignee_name": "Wareneingang",
                "status": "Status",
                "product_names": "Produkt",
                "product_numbers": "Artikelnummer",
                "supplier_names": "Lieferunternehmen"
            },
            "invoice_props": {
                "invoice_number": "Rechnungsnummer",
                "invoice_number_formatted": "Interne RE-Nummer",
                "procurement_number": "Bestellnummer",
                "creator_name": "Hochgeladen von",
                "client_name": "Unternehmen",
                "cost_center_name": "Kostenstellen-Name",
                "cost_center_number": "Kostenstellen-Nummer",
                "supplier_name": "Lieferunternehmen",
                "ledger_account_name": "Sachkonto",
                "status": "Status",
                "product_names": "Produkt",
                "product_numbers": "Artikelnummer"
            },
            "time_ranges": {
                "today": "heute",
                "yesterday": "gestern",
                "past_7_days": "letzte 7 Tage",
                "past_30_days": "letzte 30 Tage",
                "this_week": "diese Woche",
                "last_week": "letzte Woche",
                "this_month": "dieser Monat",
                "last_month": "letzter Monat",
                "this_year": "dieses Jahr",
                "last_year": "letztes Jahr"
            }
        },
        "export": {
            "all_information": "Alle Informationen",
            "export_procurement": "Bestellanforderungen exportieren",
            "export_documents": "Dokumente exportieren",
            "registers": "Einträge",
            "export": "Export",
            "to_export": "Exportieren",
            "export_with_products": "Export mit Produkten",
            "custom_export": "Konfigurierter Export",
            "select_fields": "Wählen Sie die Felder aus, welche exportiert werden sollen.",
            "select_all": "Alle auswählen",
            "deselect_all": "Alle entfernen",
            "general": "Allgemein",
            "products": "Produkte",
            "include_product": "Inkl. Produkte",
            "filter": " Filter",
            "no_filter": "kein Filter",
            "labels": {
                "procurement": {
                    "order_number_formatted": "LB-Nummer",
                    "name": "Bezeichnung",
                    "status": "Status",
                    "creator_name": "Erstellt von",
                    "total_price_brutto": "Gesamt Brutto",
                    "total_price_netto": "Gesamt Netto",
                    "created_at": "Erstelldatum",
                    "client_number": "Unternehmen Nummer",
                    "client_name": "Unternehmen Name",
                    "department_name": "Abteilung",
                    "cost_center_name": "Kostenstelle Nummer",
                    "cost_center_number": "Kostenstelle Name",
                    "consignee_name": "Wareneingang",
                    "shipping_date": "Lieferdatum",
                    "shipping_name": "Lieferanschrift Name",
                    "shipping_consignee": "Lieferanschrift Name empfangender Person",
                    "shipping_dropoff_location": "Lieferanschrift Name des Ablageorts",
                    "shipping_address": "Lieferanschrift Straße und Nr.",
                    "shipping_complement": "Lieferanschrift Straße 2",
                    "shipping_country": "Lieferanschrift Land",
                    "shipping_zipcode": "Lieferanschrift PLZ",
                    "shipping_city": "Lieferanschrift Stadt",
                    "shipping_phone_country_code": "Lieferanschrift Telefonland",
                    "shipping_phone_number": "Lieferanschrift Telefonnummer",
                    "billing_name": "Rechnungsanschrift Name",
                    "billing_consignee": "Rechnungsanschrift Name empfangender Person",
                    "billing_dropoff_location": "Rechnungsanschrift Name des Ablageorts",
                    "billing_address": "Rechnungsanschrift Straße und Nr.",
                    "billing_complement": "Rechnungsanschrift Straße 2",
                    "billing_country": "Rechnungsanschrift Land",
                    "billing_zipcode": "Rechnungsanschrift PLZ",
                    "billing_city": "Rechnungsanschrift Stadt",
                    "billing_phone_country_code": "Rechnungsanschrift Telefonland",
                    "billing_phone_number": "Rechnungsanschrift Telefonnummer"
                },
                "product": {
                    "product_name": "Produktname",
                    "product_number": "Artikelnummer",
                    "product_supplier_name": "Lieferunternehmen Name",
                    "product_supplier_email": "Lieferunternehmen E-Mail",
                    "product_cost_center_name": "Kostenstelle Name (Produkt)",
                    "product_cost_center_number": "Kostenstelle Nummer (Produkt)",
                    "product_tax": "Steuer",
                    "product_quantity": "Menge (Produkt)",
                    "product_total_netto": "Gesamt-Netto (Produkt)",
                    "product_total_brutto": "Gesamt-Brutto (Produkt)",
                    "product_netto": "Einzelpreis-Netto",
                    "product_brutto": "Einzelpreis-Brutto",
                    "product_unit": "Einheit",
                    "product_img_url": "Bild URL",
                    "product_shop_url": "Shop URL",
                    "product_shipping_name": "Lieferanschrift Name (Produkt)",
                    "product_shipping_consignee": "Lieferanschrift Name empfangender Person (Produkt)",
                    "product_shipping_dropoff_location": "Lieferanschrift Name des Ablageorts (Produkt)",
                    "product_shipping_address": "Lieferanschrift Straße und Nr. (Produkt)",
                    "product_shipping_complement": "Lieferanschrift Straße 2 (Produkt)",
                    "product_shipping_country": "Lieferanschrift Land (Produkt)",
                    "product_shipping_zipcode": "Lieferanschrift PLZ (Produkt)",
                    "product_shipping_city": "Lieferanschrift Stadt (Produkt)",
                    "product_shipping_phone_country_code": "Lieferanschrift Telefonland (Produkt)",
                    "product_shipping_phone_number": "Lieferanschrift Telefonnummer (Produkt)"
                },
                "invoice": {
                    "invoice_number_formatted": "RE-Nummer",
                    "number": "Rechnungsnummer",
                    "creator_name": "Hochgeladen von",
                    "status": "Status",
                    "procurement_name": "Bestellanforderung",
                    "procurement_number": "Bestellnummer",
                    "client_number": "Unternehmen Nummer",
                    "client_name": "Unternehmen Name",
                    "department_name": "Abteilung",
                    "cost_center_name": "Kostenstelle Nummer",
                    "cost_center_number": "Kostenstelle Name",
                    "total_price_netto": "Gesamt Netto",
                    "total_price_brutto": "Gesamt Brutto",
                    "created_at": "Hochgeladen am",
                    "invoice_date": "Rechnungsdatum",
                    "due_date": "Fälligkeitsdatum",
                    "delivery_date": "Leistungsdatum",
                    "currency": "Währung",
                    "ledger_account_name": "Sachkonto Name",
                    "ledger_account_number": "Sachkonto Nummer",
                    "posting_key_number": "Steuerschlüssel Nummer",
                    "posting_key_tax": "Steuerschlüssel Steuer",
                    "supplier_name": "Lieferunternehmen"
                }
            }
        },
        "actions": {
            "tour": {
                "procurement_reassignment_title": "Bestellanforderung zuweisen/klären",
                "procurement_reassignment_msg": "Diese Aufgabe liegt aktuell bei einer anderen Person. Mit diesem Button können Sie die Aufgabe zur Klärung oder zur weiteren Bearbeitung einer dritten Person oder sich selbst zuweisen. In der Klärung können Bestellanforderungen auch storniert werden.",
                "invoice_reassignment_title": "Rechnung zuweisen/klären",
                "invoice_reassignment_msg": "Diese Aufgabe liegt aktuell bei einer anderen Person. Mit diesem Button können Sie die Aufgabe zur Klärung oder zur weiteren Bearbeitung einer dritten Person oder sich selbst zuweisen. In der Klärung können Rechnungen auch ausgesteuert werden."
            },
            "skip_confirm_dialog_title": "Belegerkennung abbrechen",
            "skip_confirm_dialog_msg": "Sind Sie sicher, dass Sie die Erkennung der Rechnungsdaten abbrechen und die Rechnungsdaten manuell erfassen wollen?",
            "button_hint": {
                "to_approval": "Zur Freigabe",
                "to_send_order": "Zur Beauftragung",
                "approve": "Freigeben",
                "self_assign": "Annehmen",
                "finish_order": "Beauftragung abschließen",
                "finish_delivery": "Abschließen",
                "skip_recognition": "Belegerkennung abbrechen",
                "to_export": "Zum Export",
                "default": "Fortsetzen",
                "cancel_or_assign_procurement": "Zuweisen/Stornieren",
                "cancel_or_assign_invoice": "Zuweisen/Aussteuern",
                "clearing_or_assign": "Zuweisen/Klären"
            },
            "assignment_tooltip": "Klärung oder Zuweisung",
            "assignment_dialog": {
                "title_procurement_assign_or_cancel": "Zuweisung oder Stornierung",
                "title_invoice_assign_or_cancel": "Zuweisung oder Aussteuerung",
                "title_assign": "Zuweisung",
                "title_clearing_or_assign": "Klärung oder Zuweisung",
                "assign_title": "Aktuelle Aufgabe anderer Person zuweisen",
                "assign_msg": "Die aktuell zugewiesene Person ist nicht verfügbar oder nicht für diese Aufgabe zuständig, daher soll eine andere Person diese Aufgabe übernehmen.",
                "clearing_title": "Zur Klärung zuweisen",
                "clearing_msg": "Die aktuell zugewiesene Person ist verfügbar und für diese Aufgabe zuständig, aber der Vorgang kann aus anderen Gründen so nicht weiter bearbeitet werden. Daher möchten Sie einen Klärungsprozess einleiten.",
                "cancel_procurement_title": "Stornierung",
                "cancel_procurement_msg": "Die Bestellung wird unwiderruflich storniert und kann nicht weiter bearbeitet werden. Stornierte Bestellungen finden Sie in der Übersicht unter dem Status \"Abgewiesen\".",
                "cancel_invoice_title": "Aussteuerung",
                "cancel_invoice_msg": "Die Rechnung wird unwiderruflich ausgesteuert und kann nicht weiter bearbeitet werden. Ausgesteuerte Rechnungen finden Sie in der Übersicht aller Belege unter dem Status \"Ausgesteuert\".",
                "assign_to": "Zuweisen an",
                "reason": "Grund",
                "clearing_footer_info": "Die aktuelle Aufgabe wird abgebrochen. Es wird eine neue Aufgabe für die ausgewählte Person zur Klärung gestartet.",
                "reassignment_footer_info": "Es wird nur die bearbeitende Person der aktuellen Aufgabe gewechselt.",
                "cancel_invoice_btn": "Aussteuern",
                "cancel_procurement_btn": "Stornieren",
                "assign_btn": "Zuweisen",
                "clearing_dialog": {
                    "title": "Vorbelegung von Klärung",
                    "procurement_message": "Die Person im Schritt „Klärung“ ergibt sich aus verschiedenen Vorbelegungen von zuständigen Personen je Abteilung, Unternehmen, einer globalen Rollenvorbelegung und der Person, welche die Bestellung erstellt hat. Die Person, die in der Liste zuoberst steht, wird für die Aufgabe vorbelegt.",
                    "invoice_message": "Die Person im Schritt „Klärung“ ergibt sich aus verschiedenen Vorbelegungen von zuständigen Personen je Unternehmen, einer globalen Rollenvorbelegung und der Person, welche die Rechnung erstellt hat. Die Person, die in der Liste zuoberst steht,wird für die Aufgabe vorbelegt.",
                    "department_form": "Abteilung im Formular",
                    "department_creator": "Abteilung der bestellenden Person",
                    "client_form": "Unternehmen im Formular",
                    "client_creator": "Unternehmen der bestellenden Person",
                    "no_selection": "noch nicht ausgewählt",
                    "no_action": "nicht anwendbar"
                },
                "cancellation_dialog": {
                    "title_procurement": "Stornierung",
                    "btn_procurement": "Stornieren",
                    "message_procurement": "Möchten Sie diese Bestellung wirklich stornieren?",
                    "title_invoice": "Aussteuerung",
                    "btn_invoice": "Aussteuern",
                    "message_invoice": "Möchten Sie diese Rechnung wirklich aussteuern?"
                }
            }
        },
        "tour": {
            "overview": {
                "search_box": "Mit der Suche können Sie die Bestellanforderungen unter \"Meine Aufgaben\" und \"Alle Belege\" filtern und nach verschiedenen Informationen suchen, um bestimmte Bestellanforderungen schneller zu finden.",
                "tab_tasks": "Hier sehen Sie die Bestellanforderungen, für die Sie aktuell zuständig sind. In der rechten Spalte sehen Sie den Status Ihrer Aufgaben.",
                "tab_all_procurements": "In dieser Baumstruktur finden Sie alle Bestellanforderungen, nach aktuellem Status sortiert.",
                "refresh_btn": "Hier können Sie die Übersicht über die Bestellanforderungen aktualisieren.",
                "type_filter": "Hier können Sie nach dem Typ der Aufgabe filtern."
            },
            "procurement": {
                "form_title": "Formular der Bestellanforderung",
                "form": "Hier sehen Sie Informationen zu der Bestellanforderung.",
                "approve_btn_title": "Bestellanforderung weiterleiten",
                "approve_btn": "Wenn Sie eine Aufgabe erledigt haben und die Bestellanforderung an die nächste zuständige Person oder den nächsten Schritt weiterleiten wollen, drücken Sie den blauen Haken.",
                "reassign_btn": "Wenn Sie die Aufgabe nicht abschließen wollen, weil es z.B. noch Unklarheiten gibt oder eine andere Person die Aufgabe weiterbearbeiten soll, können Sie den roten Button drücken. Dann können Sie die Aufgabe entweder abgeben oder jemandem zur Klärung zuweisen. In der Klärung können Bestellanforderungen auch storniert werden.",
                "add_comment": "Über das Kommentarfeld können Sie zu der Bestellanforderung einen Kommentar abgeben. Dieser wird dann in der Timeline angezeigt.",
                "timeline_title": "Timeline",
                "timeline": "Die Timeline zeigt den vergangenen und zukünftigen Ablauf der Bestellanforderung sowie den aktuellen Status (blau) und Kommentare zu der Bestellanforderung. Über die Zahnrad-Schaltfläche kann die Sortierung umgekehrt werden.",
                "timeline_preferences": "Über diesen Button können Anzeigeeinstellungen an der Timeline vorgenommen werden. Beispielsweise kann eingestellt werden, ob die Ereignisse auf- oder absteigend sortiert werden sollen und ob Bearbeitungen der Bestellanforderung ein- oder ausgeblendet werden sollen."
            },
            "invoice": {
                "form_title": "Formular der Rechnung",
                "form": "Hier können Sie die Informationen zu der Rechnung erfassen oder bearbeiten.",
                "add_line": "Hier können Sie eine Rechnungsposition hinzufügen.",
                "edit_lines": "Hier können Sie Informationen zur der Rechnungspositionen anpassen.",
                "approve_btn_title": "Rechnung weiterleiten",
                "approve_btn": "Wenn Sie eine Aufgabe erledigt haben und die Rechnung an die nächste zuständige Person oder den nächsten Schritt weiterleiten wollen, drücken Sie den blauen Haken.",
                "reassign_btn": "Wenn Sie die Aufgabe nicht abschließen wollen, weil es z.B. noch Unklarheiten gibt oder eine andere Person die Aufgabe weiterbearbeiten soll, können Sie den roten Button drücken. Dann können Sie die Aufgabe entweder abgeben oder jemandem zur Klärung zuweisen. In der Klärung können Rechnung auch ausgesteuert werden.",
                "add_comment": "Über das Kommentarfeld können Sie zu der Rechnung einen Kommentar abgeben. Dieser wird dann in der Timeline angezeigt.",
                "timeline_title": "Timeline",
                "timeline": "Die Timeline zeigt den vergangenen und zukünftigen Ablauf der Rechnung sowie den aktuellen Status (blau) und Kommentare zu der Rechnung. Über die Zahnrad-Schaltfläche kann die Sortierung umgekehrt werden.",
                "timeline_preferences": "Über diesen Button können Anzeigeeinstellungen an der Timeline vorgenommen werden. Beispielsweise kann eingestellt werden, ob die Ereignisse auf- oder absteigend sortiert werden sollen und ob Bearbeitungen der Rechnung ein- oder ausgeblendet werden sollen."
            },
            "welcome": {
                "logo_title": "Willkommen bei liftbase!",
                "logo": "<p>Um Ihnen den Einstieg zu erleichtern, erklären wir Ihnen das wichtigste mit einer Tour durch liftbase.</p> <p>Verwenden Sie die Buttons unten oder Ihre Pfeiltasten, um durch die Tour zu navigieren.</p>",
                "menu": "In der oberen rechten Ecke befindet sich das Hauptmenü, über das Sie alle wichtigen Bereiche von liftbase finden.",
                "menu_help": "Sie können die einzelnen Abschnitte der Tour jederzeit beenden und zu einem späteren Zeitpunkt im Hilfe-Menü neu starten.",
                "menu_admin": "Hier finden Sie den nächsten Abschnitt der Tour."
            }
        }
    },
    "operator_area": {
        "title": "Operator",
        "tenant_menu": "Tenants",
        "procurement_menu": "Bestellanforderungen",
        "invoice_menu": "Rechnungen",
        "no_permission": "Sie haben keine Berechtigung, diesen Bereich zu sehen.",
        "tenant": {
            "description": "Tenant",
            "new": "Neuer Tenant",
            "edit": "Bearbeiten",
            "archive": "Archivieren",
            "delete": "Löschen",
            "activate": "Wiederherstellen",
            "procurement_count": "BANF",
            "user_count": "Benutzer",
            "automatic_billing": "Automatische Abrechnung",
            "tabs": {
                "general": "Allgemein",
                "invoice_config": "Abrechnung"
            },
            "invoice": {
                "items": {
                    "label": "Zusatzpositionen",
                    "description": "Bezeichnung",
                    "price": "Netto-Betrag",
                    "start_date": "Startdatum",
                    "end_date": "Enddatum"
                }
            },
            "contact_email_tooltip": "Mit dieser E-Mail kann der Tenant sich erstmal einloggen.",
            "no_bank_info": "Die Daten sind noch nicht vorhanden und können nur vom Tenant erzeugt werden.",
            "no_connection_with_easybill": "Dieser Tenant ist noch nicht mit Easybill verbunden. Bitte hinterlegen Sie die Tenant-ID im Easybill System.",
            "sync_with_easybill": "Alle Daten von Easybill abrufen",
            "update_from_easybill_btn": "Von Easybill abrufen",
            "update_from_easybill_tooltip": "Tenant von Easybill abrufen",
            "send_to_easybill_btn": "In Easybill erstellen",
            "send_to_easybill_tooltip": "Tenant in Easybill erstellen",
            "create_and_send_invoice_tooltip": "Rechnung in Easybill erstellen und an Kunden absenden",
            "create_and_send_invoice_btn": "Rechnung erstellen/absenden",
            "open_invoices": "Abrechnungen ansehen",
            "delete_procurements": "BANF Löschen",
            "delete_invoices": "Rechnungen Löschen",
            "confirm_before_delete_msg": "<p>Möchten Sie diesen Tenant und alle seinen Daten wirklich von der Datenbank löschen?</p><p> <i>Diese Aktion kann nicht rückgängig gemacht werden.</i></p><p><b>Geben Sie den Namen des Tenants an:</b></p>",
            "confirm_before_delete_error_msg": "Der Tenant wurde nicht gelöscht, weil der Name falsch eingegeben wurde.",
            "deleted_msg": "Der Tenant wurde endgültig gelöscht.",
            "updated_msg": "Es wurde aktualisiert.",
            "created_msg": "Es wurde erstellt.",
            "sync_msg_error": "Dieser Tenant ist noch nicht mit Easybill verbunden. Bitte hinterlegen Sie die Tenant-ID im Easybill System.",
            "create_invoice_dialog": {
                "title": "Abrechnung erstellen",
                "message": "Wählen Sie den Zeitraum für die Rechnung aus.",
                "date_placeholder": "Wählen Sie einen Monat aus.",
                "create_btn": "Erstellen"
            },
            "validation": {
                "name_required": "Bitte geben Sie einen Namen ein.",
                "name_exists": "Der Name existiert bereits.",
                "email_required": "Bitte geben Sie eine Kontakt-E-Mail ein.",
                "email_invalid": "Bitte geben Sie eine gültige E-Mail ein."
            }
        },
        "procurement": {
            "title": "Bestellanforderungen von Tenant",
            "delete_title": "Bestellanforderung löschen",
            "result_found": "Treffer",
            "delete_result_found": "Treffer löschen",
            "start_date": "Startdatum",
            "end_date": "Enddatum",
            "range_separator": "bis",
            "confirm_delete_msg": "Sind Sie sicher, dass Sie die ausgewählten Bestellanforderungen endgültig aus der Datenbank löschen möchten?",
            "confirm_delete_title": "Bestellanforderung löschen",
            "confirm_delete_btn": "Löschen"
        },
        "invoice": {
            "title": "Rechnungen von Tenant",
            "delete_title": "Rechnungen löschen",
            "result_found": "Treffer",
            "delete_result_found": "Treffer löschen",
            "start_date": "Startdatum",
            "end_date": "Enddatum",
            "range_separator": "bis",
            "confirm_delete_msg": "Sind Sie sicher, dass Sie die ausgewählten Rechnungen endgültig aus der Datenbank löschen möchten?",
            "confirm_delete_title": "Rechnung löschen",
            "confirm_delete_btn": "Löschen"
        }
    },
    "timeline": {
        "title": "Timeline",
        "preferences": {
            "order_asc": "Älteste zuerst",
            "order_desc": "Neuste zuerst"
        },
        "text": {
            "description_assignment": "Die Aufgabe wurde angenommen.",
            "start": {
                "name": "Neue Bestellanforderung",
                "description_past_successful": "Neue Bestellanforderung wurde erstellt."
            },
            "select-product": {
                "is_searchable": true,
                "simple_name": "Produktauswahl",
                "name": "Produktauswahl (Stufe {index})",
                "description_past_unsuccessful": "Produktauswahl wurde abgebrochen.",
                "description_past_successful": "Produkte für diese Bestellung wurden ausgewählt.",
                "description_present": "Bitte Produkte für diese Bestellung auswählen.",
                "description_future": "Produkte für diese Bestellung müssen ausgewählt werden."
            },
            "approval": {
                "is_searchable": true,
                "simple_name": "Freigabe Bestellanforderung",
                "name": "Freigabe Bestellanforderung (Stufe {index})",
                "description_past_unsuccessful": "Freigabe der Bestellung wurde abgebrochen.",
                "description_past_successful": "Bestellanforderung wurde geprüft und freigegeben.",
                "description_present": "Bitte Bestellanforderung prüfen und freigeben.",
                "description_future": "Bestellanforderung muss geprüft und freigegeben werden."
            },
            "send-order": {
                "is_searchable": true,
                "name": "Beauftragung der Bestellung",
                "description_past_unsuccessful": "Bestellung bei Lieferunternehmen wurde abgebrochen.",
                "description_past_successful": "Bestellung wurde bei Lieferunternehmen beauftragt.",
                "description_present": "Bitte Bestellung bei Lieferunternehmen beauftragen.",
                "description_future": "Bestellung muss bei Lieferunternehmen beauftragt werden."
            },
            "delivery": {
                "is_searchable": true,
                "name": "Bestätigung der Lieferung",
                "description_past_unsuccessful": "Bestätigung des Wareneingangs wurde abgebrochen.",
                "description_past_successful": "Ware wurde geliefert und Wareneingang bestätigt.",
                "description_present": "Bitte den Wareneingang bestätigen.",
                "description_future": "Wareneingang muss bestätigt werden."
            },
            "reassignment": {
                "name": "Zuweisung",
                "description_past_unsuccessful": "",
                "description_past_successful": "hat die Aufgabe {name} zugewiesen.",
                "description_past_successful_from_group": "hat die Aufgabe angenommen."
            },
            "clearing": {
                "is_searchable": true,
                "name": "Klärung",
                "description_past_unsuccessful": "Klärung wurde abgebrochen.",
                "description_past_successful": "Unklarheiten der Bestellung wurden geklärt.",
                "description_present": "Bitte die Bestellung klären.",
                "description_finished_unsuccessful": "Bestellung wurde storniert und archiviert."
            },
            "order-sent-internal": {
                "name": "Systemmeldung",
                "description_past_successful": "Die Bestellung <b>({first_product_name})</b> beim Lieferunternehmen <b>{supplier}</b> wurde bestätigt."
            },
            "order-sent-punchout": {
                "name": "Systemmeldung",
                "description_past_successful": "Die Bestellung <b>({first_product_name})</b> wurde elektronisch an das Lieferunternehmen <b>{supplier}</b> gesendet."
            },
            "product-received": {
                "name": "Systemmeldung",
                "description_past_successful": "<b>{quantity} {unit}</b> eingegangen:<p>{product}</p><span style=\"color:#9a9898\">{supplier}</span>"
            },
            "invoice-upload": {
                "name": "Neue Rechnung",
                "description_past_successful": "Die Rechnung wurde hochgeladen."
            },
            "invoice-recognition": {
                "is_searchable": true,
                "name": "Rechnungserkennung",
                "description_past_unsuccessful": "Die Erkennung der Rechnungsdaten wurde abgebrochen.",
                "description_past_successful": "Die Erkennung der Rechnungsdaten wurde abgeschlossen.",
                "description_present": "Die Rechnungsdaten werden ausgelesen."
            },
            "invoice-manual-check": {
                "is_searchable": true,
                "name": "Manuelle Prüfung",
                "description_past_unsuccessful": "Die manuelle Prüfung wurde nicht abgeschlossen.",
                "description_past_successful": "Die Rechnung wurde manuell geprüft.",
                "description_present": "Bitte die Rechnungsdaten prüfen.",
                "description_future": "Die Rechnung muss manuell geprüft werden."
            },
            "invoice-clearing": {
                "is_searchable": true,
                "name": "Klärung Buchhaltung",
                "description_past_unsuccessful": "Klärung wurde abgebrochen.",
                "description_past_successful": "Unklarheiten der Rechnung wurden geklärt.",
                "description_present": "Bitte die Rechnung klären.",
                "description_finished_unsuccessful": "Die Rechnung wurde ausgesteuert und archiviert."
            },
            "invoice-export": {
                "is_searchable": true,
                "name": "Export",
                "description_past_unsuccessful": "Die Rechnung wurde nicht exportiert.",
                "description_past_successful": "Die Rechnung wurde an {export_name} übermittelt.",
                "description_present": "Die Rechnung steht zum Export bereit.",
                "description_future": "Die Rechnung steht zum Export bereit."
            },
            "invoice-accounting": {
                "is_searchable": true,
                "name": "Buchhaltung",
                "description_past_unsuccessful": "Die Buchhaltung wurde abgebrochen.",
                "description_past_successful": "Die Buchhaltung wurde geprüft.",
                "description_present": "Bitte die Buchhaltung prüfen.",
                "description_future": "Die Buchhaltung muss geprüft werden."
            },
            "skip": {
                "name": "Abbruch",
                "description_past_successful": "hat den Schritt abgebrochen."
            }
        }
    },
    "jexcel": {
        "noRecordsFound": "Keine Einträge gefunden.",
        "showingPage": "Seite {0} von {1}",
        "show": "Zeigen ",
        "entries": " Einträge",
        "insertANewColumnBefore": "Spalte davor einfügen",
        "insertANewColumnAfter": "Spalte danach einfügen",
        "deleteSelectedColumns": "Ausgewählte Spalten löschen",
        "renameThisColumn": "Spalte umbenennen",
        "orderAscending": "Aufsteigend sortieren",
        "orderDescending": "Absteigend sortieren",
        "insertANewRowBefore": "Zeile davor einfügen",
        "insertANewRowAfter": "Zeile danach einfügen",
        "deleteSelectedRows": "Ausgewählte Zeilen löschen",
        "copy": "Kopieren",
        "paste": "Einfügen",
        "saveAs": "Speichern unter",
        "about": "Über",
        "areYouSureToDeleteTheSelectedRows": "Sind Sie sicher, dass Sie die ausgewählten Zeilen löschen wollen?",
        "areYouSureToDeleteTheSelectedColumns": "Sind Sie sicher, dass Sie die ausgewählten Spalten löschen wollen?",
        "thisActionWillDestroyAnyExistingMergedCellsAreYouSure": "Esta ação irá destruir todas as células mescladas existentes. Você tem certeza?",
        "thisActionWillClearYourSearchResultsAreYouSure": "Esta ação limpará seus resultados de pesquisa. Você tem certeza?",
        "thereIsAConflictWithAnotherMergedCell": "Há um conflito com outra célula mesclada",
        "invalidMergeProperties": "Propriedades mescladas inválidas",
        "cellAlreadyMerged": "Cell já mesclado",
        "noCellsSelected": "Nenhuma célula selecionada"
    },
    "system_comment": {
        "name": {
            "allocation": "Zuordnung",
            "datev_export": "DATEV Exportierung",
            "export_error": "Fehler beim Export",
            "export_detail": "Exportprotokoll",
            "repeat_recognition": "Rechnungserkennung"
        },
        "recognition_was_repeated": "{user_name} hat die Belegerkennung wiederholt.",
        "attach_new_invoice_to_procurement": "Die Rechnung <b>{link_to_invoice}</b> von <b>{supplier_name}</b> wurde dieser Bestellanforderung von <b>{user_name}</b> zugeordnet.",
        "attach_new_invoice_to_procurement_without_supplier": "Die Rechnung <b>{link_to_invoice}</b> wurde dieser Bestellanforderung von <b>{user_name}</b> zugeordnet.",
        "change_procurement_of_invoice": "Die Zuordnung der Rechnung <b>{link_to_invoice}</b> von <b>{supplier_name}</b> wurde wieder entfernt. <br>Die Rechnung wurde der Bestellanforderung {link_to_procurement} zugeordnet.",
        "change_procurement_of_invoice_without_supplier": "Die Zuordnung der Rechnung <b>{link_to_invoice}</b> wurde wieder entfernt. <br>Die Rechnung wurde der Bestellanforderung {link_to_procurement} zugeordnet.",
        "detach_procurement_of_invoice": "Die Zuordnung der Rechnung <b>{link_to_invoice}</b> von <b>{supplier_name}</b> wurde wieder entfernt.",
        "detach_procurement_of_invoice_without_supplier": "Die Zuordnung der Rechnung <b>{link_to_invoice}</b> wurde wieder entfernt.",
        "attach_external_invoice_to_procurement": "Die Rechnung {invoice_number} von <b>{supplier_name}</b> wurde dieser Bestellanforderung von <b>{invoice_by}</b> zugeordnet.",
        "insert_datev_note": "Die Rechnung <b>{link_to_invoice}</b> von <b>{supplier_name}</b> wurde an DATEV Unternehmen online übermittelt.",
        "insert_datev_note_without_supplier": "Die Rechnung <b>{link_to_invoice}</b> wurde an DATEV Unternehmen online übermittelt.",
        "on_ms365_export_error_generic": "Fehler beim Export der Rechnung an D365. Bitte wenden Sie sich an den Support.",
        "on_ms365_export_validation_error": "Fehler beim Export der Rechnung an D365. Bitte wenden Sie sich an den Support. Fehler: {error}",
        "on_export_error": "Die Rechnung konnte nicht an DATEV Unternehmen online übermittelt werden. {error}",
        "on_export_error_generic": "Fehler beim Export der Rechnung an DATEV. Bitte wenden Sie sich an den Support.",
        "on_export_error_validation": "{supplier_external_id_export_validation}{supplier_name_export_validation} Bitte wenden Sie sich an eine Person mit Administrationsrechten um die Konfiguration zu überprüfen.",
        "on_export_error_datev_access_is_not_active": "Die Verbindung mit DATEV muss hergestellt werden. Bitte wenden Sie sich an eine Person mit Administratorenrechten.",
        "on_export_error_client_has_no_datev_access": "Das Unternehmen {client_name} hat keine DATEV-Zugangsdaten hinterlegt. Bitte wenden Sie sich an eine Person mit Administratorenrechten.",
        "on_export_error_client_has_no_datev_permission": "Beim der Übermittlung an DATEV ist ein Fehler aufgetreten. Der eingerichtete DATEV-Zugang hat keine Berechtigung für das Unternehmen {client_name}. Bitte wenden Sie sich an eine Person mit Administrationsrechten, um den Zugang oder die konfigurierten Unternehmen oder die Berechtigungen innerhalb DATEV zu überprüfen.",
        "on_export_error_datev_has_protocol_entries": "Fehler beim Export der Rechnung an DATEV. Bitte prüfen Sie das Exportprotokoll oder wenden Sie sich an den Support.",
        "on_export_error_no_fiscal_year": "Beim der Übermittlung an DATEV ist ein Fehler aufgetreten. Das Rechnungsdatum liegt nicht im DATEV Geschäftsjahr. Bitte wenden Sich sich an eine Person mit Administrationsrechten, um das Geschäftsjahr in DATEV zu überprüfen.",
        "on_export_error_invoice_not_active_for_fiscal_year": "Beim der Übermittlung an DATEV ist ein Fehler aufgetreten. Das DATEV Geschäftsjahr ist nicht für die Übermittlung von Eingangsrechnungen freigegeben. Bitte wenden Sich sich an eine Person mit Administrationsrechten, um das Geschäftsjahr in DATEV zu überprüfen.",
        "on_export_error_no_account_combination": "Konto-Kombination konnte nicht gefunden werden: {combination}.",
        "insert_datev_protocol_note": "<b>Status</b>: {status} <br><br> <b>Protokolleinträge</b>: <br> {protocol}",
        "labels": {
            "invoice_number_label": "Rechnungsnummer",
            "procurement_number_label": "Bestellnummer",
            "invoice_date_label": "Rechnungsdatum",
            "invoice_due_date_label": "Fälligkeit",
            "supplier_label": "Lieferunternehmen",
            "client_label": "Unternehmen",
            "total_netto_label": "Gesamt-Netto",
            "total_brutto_label": "Gesamt-Brutto",
            "tax_label": "Steuersatz",
            "positions_count_label": "Position(en)",
            "no_data_read_label": "Keine Daten erkannt.",
            "supplier_external_id_export_validation": "Die externe ID des Lieferunternehmens muss vorkonfiguriert werden. ",
            "supplier_name_export_validation": "Der Name des Lieferunternehmens muss vorkonfiguriert werden. ",
            "recognition_was_repeated": "{user_name} hat die Belegerkennung wiederholt.",
        }
    },
    "login": {
        "email": {
            "title": "Login mit E-Mail",
            "instructions": "Bitte geben Sie Ihre E-Mail-Adresse ein. Klicken Sie anschließend auf den Login-Link in der E-Mail.",
            "punchin_instructions": "Um den Vorgang abzuschließen, müssen Sie sich einloggen und den Genehmigungsprozess der Bestellung starten.",
            "tenant": "Tenant",
            "place_holder": "E-Mail-Adresse",
            "last_used_emails": "Zuletzt verwendete E-Mail-Adressen",
            "delete_last_used_emails": "\"Zuletzt verwendet\" löschen",
            "has_no_account": "Noch keinen Zugang?",
            "login_link_via_email": "Login-Link per E-Mail erhalten",
            "finish_login_title": "Login abschließen",
            "finish_login_instructions": "Sie erhalten jetzt eine E-Mail mit einem Login-Link. Bitte klicken Sie auf den Link, um den Login abzuschließen. <br><br>Die Login-E-Mail wurde an <br><b>{email}</b><br> geschickt und sollte innerhalb kurzer Zeit eintreffen. Bitte prüfen Sie ggf. Ihren Spam-Ordner.",
            "forgot_passwort": {
                "title": "Passwort vergessen",
                "login_via_email": "Login per E-Mail",
                "instructions": "In liftbase gibt es keine Passwörter. Wir schicken Ihnen eine E-Mail mit einem Login-Link. Oder Sie nutzen Passkeys."
            },
            "no_email": "Keine E-Mail",
            "no_email_validation": "Bitte geben Sie Ihre E-Mail-Adresse ein, um sich anzumelden.",
            "check_email_title": "E-Mail prüfen",
            "check_email_message": "Bitte prüfen Sie jetzt Ihr E-Mail Postfach und klicken Sie auf den Login-Link."
        },
        "or": "oder",
        "passkey": {
            "title": "Login mit Passkey",
            "instructions": "Bitte klicken Sie auf den Button um sich via Passkey einzuloggen.",
            "login": "Mit Passkey anmelden",
            "what_is": {
                "title": "Was sind Passkeys?",
                "first": "Login ohne auf E-Mail zu warten",
                "second": "Mit Ihrem Fingerabdruck, Ihrem Gesicht oder PIN",
                "third": "Jetzt in wenigen Schritten in Ihrem Profil einrichten"
            },
            "error": "Fehler beim Login mit Passkey. Bitte versuchen Sie es erneut."
        }
    },
    "languages": {
        "de": "Deutsch",
        "en": "Englisch",
        "it": "Italienisch",
        "cz": "Tschechisch",
        "sk": "Slowakisch"
    },
    "translation-component": {
        "repeated": "Sie können dieselbe Sprache nicht mehrmals auswählen.",
        "language_empty": "Sie müssen die Sprache auswählen.",
        "translation_empty": "Sie müssen den Text für diese ausgewählte Sprache eingeben.",
        "both_empty": "Sie müssen die Sprache sowie den Text eingeben.",
        "language": "Sprache",
        "no_translation": "Es gibt keinen Text für diese Sprache registriert.",
        "at_least_one_required": "Sie müssen mindestens eine Bezeichnung angeben.",
        "text_without_translation": "Ohne Übersetzung"
    }
}